/* eslint-disable prefer-destructuring */

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Theme } from "@mui/material/styles";

const item = (theme: Theme | any, ownerState: any) => {
    const { palette, borders, functions, transitions } = theme;

    const { primary } = palette;
    const { borderRadius } = borders;
    const { rgba } = functions;

    return {
        pl: 3,
        width: "100%",
        borderRadius: borderRadius.md,
        cursor: "pointer",
        transition: transitions.create("background-color", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter,
        }),

        "&:hover, &:focus": {
            backgroundColor: rgba(primary.main, 0.1),
        },
    };
};

const itemContent = (theme: Theme, ownerState: any) => {
    const { palette, typography, transitions, functions } = theme;
    const { miniSidenav, active } = ownerState;

    const { primary } = palette;
    const { size, fontWeightRegular, fontWeightBold } = typography;
    const { pxToRem } = functions;

    return {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        color: active ? primary.main : primary.dark,
        padding: `${pxToRem(12)} ${pxToRem(16)} ${pxToRem(12)} ${pxToRem(28)}`,
        marginLeft: pxToRem(8),
        userSelect: "none",
        position: "relative",
        borderLeft: "1px solid #EDEDFF",

        "& span": {
            fontWeight: active ? fontWeightBold : fontWeightRegular,
            fontSize: size.sm,
            opacity: miniSidenav ? 0 : 1,
            transition: transitions.create(["opacity", "color"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
            }),
        },

        "&::before": {
            content: `"●"`,
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: pxToRem(12),
            opacity: 1,
            fontSize: active ? size.sm : size.xxs,
        },

        "&:hover, &:focus": {
            color: primary.dark,

            span: {
                fontWeight: fontWeightRegular,
            },
        },
    };
};

const itemArrow = (theme: Theme, ownerState: any) => {
    const { palette, typography, transitions, breakpoints, functions } = theme;
    const { noCollapse, miniSidenav, open, active } = ownerState;

    const { primary } = palette;
    const { size } = typography;
    const { pxToRem, rgba } = functions;

    return {
        fontSize: `${size.lg} !important`,
        fontWeight: 700,
        marginBottom: pxToRem(-1),
        transform: open ? "rotate(0)" : "rotate(-180deg)",
        color: open || active ? primary.dark : rgba(primary.dark, 0.5),
        transition: transitions.create(["color", "transform", "opacity"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter,
        }),

        [breakpoints.up("lg")]: {
            display: noCollapse || miniSidenav ? "none !important" : "block !important",
        },
    };
};

export { item, itemContent, itemArrow };
