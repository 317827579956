import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { FormType } from "types/enums";
import { Business } from "types/setting-schema";
import NewCenter from "layouts/settings/business-page/newCenter";
import NewUnit from "layouts/settings/business-page/newUnit";
import NewResident from "layouts/settings/resident-page/newResident";
import NewFloor from "layouts/settings/components/Floor";
// i18n
import { useTranslation } from "react-i18next";

interface Props extends Business {
    callback: (updated: boolean, type: FormType) => void;
}

export const BusinessCard: React.FC<Props> = ({ callback, ...rest }) => {
    const navigate = useNavigate();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [mode, setMode] = useState<{ type: FormType } | null>(null);
    const { name, address, phone, contact, numCenters, numUnits, numResidents, _id, numFloor } = { ...rest };
    const { t: translate } = useTranslation(["common"]);

    const replaceSpace = (name: string) => {
        return name.replaceAll(" ", "-");
    };

    // Navigate to create center or unit form with business id
    const handleNavigate = (url: string) => {
        navigate(`/setting/business/${url}`, {
            state: {
                ...rest,
            },
        });
    };

    const childCallback = () => {
        setToggleModal(false);
        callback(true, mode.type);
    };

    const renderForm = () => {
        if (mode.type === FormType.CENTER) {
            return <NewCenter setToggleModal={setToggleModal} businessId={_id} callback={childCallback} />;
        } else if (mode.type === FormType.UNIT) {
            return <NewUnit setToggleModal={setToggleModal} businessId={_id} callback={childCallback} />;
        } else if (mode.type === FormType.RESIDENT) {
            return <NewResident setToggleModal={setToggleModal} businessId={_id} callback={childCallback} />;
        } else if (mode.type === FormType.FLOOR) {
            return <NewFloor setToggleModal={setToggleModal} businessId={_id} open={toggleModal} />;
        }
    };

    const handleToggleModal = (type: FormType) => {
        setToggleModal(true);
        setMode({ type: type });
    };

    return (
        <>
            {toggleModal && renderForm()}
            <Grid item xs={12} sm={6} md={4} xl={3}>
                <MDBox
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    px={3}
                    py={4}
                    bgColor="white"
                    sx={{ borderRadius: "12px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)", position: "relative" }}>
                    {/* A clickable transparent card background */}

                    <MDBox
                        width="100%"
                        height="100%"
                        top="0"
                        right="0"
                        sx={{ cursor: "pointer", position: "absolute" }}
                        onClick={() => handleNavigate(`${replaceSpace(name)}`)}></MDBox>
                    <MDBox pb={6} display="flex" flexDirection="column" height="100%">
                        <MDBox mb={3}>
                            <MDTypography variant="h2" color="text">
                                {name.length > 25 ? `${name.substring(0, 25)}...` : name}
                            </MDTypography>
                        </MDBox>
                        <MDBox mt="auto">
                            <MDBox display="flex" alignItems="center">
                                <Icon color="warning">person</Icon>
                                <MDTypography variant="subtitle2" color="text" ml={1}>
                                    {contact}
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex" alignItems="center" mt={2}>
                                <Icon color="warning">smartphone</Icon>
                                <MDTypography variant="subtitle2" color="text" ml={1}>
                                    {phone.countryCode} {phone.number}
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex" mt={2}>
                                <Icon color="warning" sx={{ mt: -0.5 }}>
                                    home
                                </Icon>
                                <MDTypography variant="subtitle2" color="text" ml={1}>
                                    {address}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </MDBox>

                    <MDBox mt="auto" display="flex" justifyContent="space-around">
                        {[FormType.CENTER, FormType.FLOOR, FormType.UNIT].map((item, idx) => (
                            <MDBox key={idx} display="flex" flexDirection="column" alignItems="center">
                                <MDTypography variant="body2" color="text">
                                    {translate(`general.type.${item}`)}
                                </MDTypography>
                                <MDTypography variant="h1" my={2} sx={{ color: " rgba(125, 15, 177, 0.7)" }}>
                                    {item === FormType.CENTER
                                        ? numCenters
                                        : item === FormType.UNIT
                                        ? numUnits
                                        : item === FormType.FLOOR
                                        ? numFloor
                                        : numResidents}
                                </MDTypography>
                                <MDButton
                                    onClick={() => {
                                        if (item === FormType.UNIT && numCenters === 0) return;
                                        handleToggleModal(item);
                                    }}
                                    variant="outlined"
                                    color={item === FormType.UNIT && numCenters === 0 ? "dark" : "primary"}
                                    iconOnly={true}>
                                    <Icon>add</Icon>
                                </MDButton>
                            </MDBox>
                        ))}
                    </MDBox>
                </MDBox>
            </Grid>
        </>
    );
};

export default BusinessCard;
