/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Theme } from "@mui/material/styles";

const collapseItem = (theme: Theme, ownerState: any) => {
    const { palette, transitions, breakpoints, boxShadows, borders, typography, functions } = theme;
    const { active, noCollapse } = ownerState;

    const { white, transparent, primary } = palette;
    const { md } = boxShadows;
    const { borderRadius } = borders;
    const { fontWeightRegular, fontWeightBold } = typography;
    const { pxToRem, rgba } = functions;

    return {
        background: active ? rgba(primary.main, 0.6) : transparent.main,
        color: active ? white.main : primary.dark,
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: `${pxToRem(8)} ${pxToRem(16)}`,
        margin: `${pxToRem(1.5)} ${pxToRem(16)}`,
        borderRadius: borderRadius.md,
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",
        fontWeight: () => {
            let weightValue = fontWeightRegular;
            if (active && noCollapse) {
                weightValue = fontWeightBold;
            } else if (active && !noCollapse) {
                weightValue = 600;
            } else {
                weightValue = fontWeightRegular;
            }
            return weightValue;
        },
        boxShadow: active ? md : "none",
        [breakpoints.up("lg")]: {
            transition: transitions.create(["box-shadow", "background-color"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
            }),
        },

        "&:hover, &:focus": {
            backgroundColor: rgba(primary.main, 0.1),
            color: primary.dark,
            fontWeight: fontWeightRegular,
        },
    };
};

const collapseIconBox = (theme: Theme, ownerState: any) => {
    const { transitions, borders, functions } = theme;

    const { borderRadius } = borders;
    const { pxToRem } = functions;

    return {
        minWidth: pxToRem(32),
        minHeight: pxToRem(32),
        color: "inherit",
        borderRadius: borderRadius.md,
        display: "grid",
        placeItems: "center",
        transition: transitions.create("margin", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
        }),
        "& .MuiIcon-fontSizeMedium": {
            fontSize: "16px",
        },
    };
};

const collapseIcon = ({ palette: { dark, gradients } }: Theme, { active }: any) => ({
    color: active ? dark.main : gradients.dark.state,
});

const collapseText = (theme: any, ownerState: any) => {
    const { typography, transitions, breakpoints, functions } = theme;
    const { miniSidenav } = ownerState;

    const { size } = typography;
    const { pxToRem } = functions;

    return {
        marginLeft: pxToRem(10),

        [breakpoints.up("lg")]: {
            opacity: miniSidenav || miniSidenav ? 0 : 1,
            maxWidth: miniSidenav || miniSidenav ? 0 : "100%",
            marginLeft: miniSidenav || miniSidenav ? 0 : pxToRem(10),
            transition: transitions.create(["opacity", "margin"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
            }),
        },

        "& span": {
            fontWeight: "inherit",
            fontSize: size.sm,
            lineHeight: 0,
        },
    };
};

const collapseArrow = (theme: Theme, ownerState: any) => {
    const { palette, typography, transitions, functions } = theme;
    const { noCollapse, miniSidenav, open, active } = ownerState;

    const { primary, white } = palette;
    const { size } = typography;
    const { pxToRem, rgba } = functions;

    return {
        fontSize: `${size.xl} !important`,
        marginBottom: pxToRem(-1),
        transform: open ? "rotate(0)" : "rotate(-180deg)",
        color: open || active ? white.dark : rgba(primary.dark, 0.5),
        transition: transitions.create(["color", "transform", "opacity"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter,
        }),

        display: noCollapse || miniSidenav || miniSidenav ? "none !important" : "block !important",
    };
};

export { collapseItem, collapseIconBox, collapseIcon, collapseText, collapseArrow };
