import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface Detail {
    name: string;
    number: number;
}

interface Props {
    collections: Detail[];
}

export const CollectionNumber: React.FC<Props> = ({ collections }) => {
    return (
        <Grid item xs={11} md={3.5}>
            <MDBox display="flex" justifyContent="center">
                {collections.map((item: Detail, idx: number) => {
                    return (
                        <MDBox key={idx} px={2} align="center">
                            <MDTypography variant="h5">{item.name}</MDTypography>
                            <MDTypography variant="h1">{item.number}</MDTypography>
                        </MDBox>
                    );
                })}
            </MDBox>
        </Grid>
    );
};

export default CollectionNumber;
