import { ReactNode, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import { Icon } from "@mui/material";
import { ToggleProp } from "types/state";
import { isNil } from "helpers/utils";
import Modal from "@mui/material/Modal";
// Declaring props types for BasicLayout
interface Props extends ToggleProp {
    children: ReactNode;
    type: string;
    open?: boolean;
    isDownload?: () => Promise<void>;
    customWidth?: string;
}
export const NewMDModal: React.FC<Props> = ({
    children,
    type,
    open,
    setToggleModal,
    setImageModal,
    isDownload,
    customWidth,
}) => {

    return (
        <Modal open={open} onClose={setToggleModal}>
            <MDBox
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: `rgba(0, 0, 0, ${type === "form" ? "0.2" : "0.4"})`,
                }}
                onClick={() => {
                    if (type === "form") setToggleModal(false);
                    else setImageModal && setImageModal(null);
                }}
            >
                <MDBox
                    width={{ xs: "90%", md: `${type === "form" ? "70%" : "90%"}` }}
                    sx={{
                        position: "relative",
                        maxWidth: `${type === "form" ? (customWidth ? customWidth : "800px") : "1000px"}`,
                        zIndex: 50,
                        backgroundColor: "white",
                        borderRadius: "8px",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        maxHeight: "80vh", // Set a maximum height for the content box
                        overflowY: "auto", // Enable vertical scrolling
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent click events from propagating to the overlay
                >
                    <MDBox m={{ xs: 2, md: 5 }} sx={{ position: "absolute", right: "0", zIndex: 10 }}>
                        <MDBox
                            shadow="md"
                            display="flex"
                            alignItems="center"
                            onClick={() => {
                                if (type === "form") setToggleModal(false);
                                else setImageModal && setImageModal(null);
                            }}
                            p={1}
                            sx={{ cursor: "pointer", backgroundColor: "#E4E9F3", borderRadius: "50%" }}
                        >
                            <Icon fontSize="small">close</Icon>
                        </MDBox>
                        {isDownload != null && (
                            <MDBox sx={{ position: "absolute", left: "-50px", top: "10px", zIndex: 10 }}>
                                <Icon
                                    fontSize="medium"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                        isDownload();
                                    }}
                                >
                                    download
                                </Icon>
                            </MDBox>
                        )}
                    </MDBox>
                    {children}
                </MDBox>
            </MDBox>
        </Modal>
    );
};
export const MDModal: React.FC<Props> = ({
    children,
    type,
    setToggleModal,
    setImageModal,
    isDownload,
    customWidth,
}) => {
    return (
        <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ position: "fixed", left: 0, top: 0, zIndex: 50, width: "100vw", height: "100vh" }}>
            <MDBox
                onClick={() => {
                    if (type === "form") setToggleModal(false);
                    else setImageModal(null);
                }}
                sx={{
                    position: "absolute",
                    width: "100vw",
                    height: "100vh",
                }}
                bgColor={`rgba(0, 0, 0, ${type === "form" ? "0.2" : "0.4"})`}></MDBox>
            <MDBox
                width={{ xs: "90%", md: `${type === "form" ? "70%" : "90%"}` }}
                sx={{
                    position: "relative",
                    maxWidth: `${type === "form" ? (customWidth ? customWidth : "800px") : "1000px"}`,
                    zIndex: 50,
                }}>
                <MDBox m={{ xs: 2, md: 5 }} sx={{ position: "absolute", right: "0", zIndex: 10 }}>
                    <MDBox
                        shadow="md"
                        display="flex"
                        alignItems="center"
                        onClick={() => {
                            if (type === "form") setToggleModal(false);
                            else setImageModal(null);
                        }}
                        p={1}
                        sx={{ cursor: "pointer", backgroundColor: "#E4E9F3", borderRadius: "50%" }}>
                        <Icon fontSize="small">close</Icon>
                    </MDBox>
                    {isDownload != null && (
                        <MDBox sx={{ position: "absolute", left: "-50px", top: "10px", zIndex: 10 }}>
                            <Icon
                                fontSize="medium"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    isDownload();
                                }}>
                                download
                            </Icon>
                        </MDBox>
                    )}
                </MDBox>
                {children}
            </MDBox>
        </MDBox>
    );
};

export default MDModal;
