import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ResidentService from "services/resident.service";
import MDBox from "components/MDBox";
import ReportTable from "./ReportTable";
import { use } from "i18next";
import MDDailyScore from "components/MDDailyScore";
import ReportService from "services/report.service";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl/FormControl";
// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import MDInput from "components/MDInput";
import { Instance } from "flatpickr/dist/types/instance";
interface Props {
    businessId?: string;
    centerId?: string;
    filter?: string;
}

interface Report {
    id: string;
    bed: string;
    name: string;
    sleepQualityLight: number[];
    leaveBedLight: number[];
    physiologicalLight: number[];
    alarmsLight: number[];
    bathRoomAlarmsLight: number[];
    sleepQuality: number[];
    leaveBedTimesAtNight: number[];
    leaveBedCurAtNight: number[];
    physiologicalBpmValue: number[];
    physiologicalRpmValue: number[];
    alarmsCountAtNight: number[];
    bathRoomAlarmTimes: number[];
    bathRoomAlarmsCur: number[];
    business?: string;
    center?: string;
}

interface SummaryData extends Report {
    sleepCircle: number;
    leaveBedCircle: number;
    physiologicalCircle: number;
    alarmsCircle: number;
    toiletTooLongCircle: number;
    CompositeScore: number[];
    sleepPercentage: number | string;
    leaveBedTimes: number;
    leaveBedCurTime: number;
    BpmValue: number | string;
    RpmValue: number | string;
    alarmCount: number;
    toiletTooLongTime: number;
    toiletTooLongCur: number;
    CompositeScoreColor: number;
}

const calculateSummaryData = (
    sleepReports: Report[],
    residentsData: any[],
    businessId?: string,
    centerId?: string,
): SummaryData[] => {
    return sleepReports.map((n, e) => {
        let match = false;
        residentsData.forEach((item) => {
            if (n.id == item.wistronId) {
                match = true;
                n.bed = item.floor;
                n.name = item.name;
                n.business = item.business;
                n.center = item.center;
            }
        });
        if (!match) {
            n.bed = "N/A";
            n.name = "N/A";
        }
        let l = n.sleepQualityLight[n.sleepQualityLight.length - 1],
            i = n.leaveBedLight[n.leaveBedLight.length - 1],
            r = n.physiologicalLight[n.physiologicalLight.length - 1],
            o = n.alarmsLight[n.alarmsLight.length - 1],
            a = n.bathRoomAlarmsLight[n.bathRoomAlarmsLight.length - 1],
            s = n.sleepQuality.filter((t) => 0 !== t),
            u = s.length ? ((s.reduce((t, n) => t + n) / s.length) * 100).toFixed(1) : 0;
        let c = n.leaveBedTimesAtNight.length ? n.leaveBedTimesAtNight.reduce((t, n) => t + n) : 0,
            h = n.leaveBedCurAtNight.length ? n.leaveBedCurAtNight.reduce((t, n) => t + n) : 0,
            d = n.physiologicalBpmValue.filter((t) => 0 !== t),
            p = n.physiologicalRpmValue.filter((t) => 0 !== t),
            b = d.length ? (d.reduce((t, n) => t + n) / d.length).toFixed(1) : 0,
            f = p.length ? (p.reduce((t, n) => t + n) / p.length).toFixed(1) : 0,
            g = n.alarmsCountAtNight.length ? n.alarmsCountAtNight.reduce((t, n) => t + n) : 0,
            A = n.bathRoomAlarmTimes.length ? n.bathRoomAlarmTimes.reduce((t, n) => t + n) : 0,
            m = n.bathRoomAlarmsCur.length ? n.bathRoomAlarmsCur.reduce((t, n) => t + n) : 0,
            y: any = [];
        n.sleepQualityLight.map(
            (t, e) => (
                0 == t &&
                    0 == n.leaveBedLight[e] &&
                    0 == n.physiologicalLight[e] &&
                    0 == n.alarmsLight[e] &&
                    0 == n.bathRoomAlarmsLight[e] &&
                    (y[e] = 0),
                (1 != t &&
                    1 != n.leaveBedLight[e] &&
                    1 != n.physiologicalLight[e] &&
                    1 != n.alarmsLight[e] &&
                    1 != n.bathRoomAlarmsLight[e]) ||
                    (y[e] = 1),
                (2 != t &&
                    2 != n.leaveBedLight[e] &&
                    2 != n.physiologicalLight[e] &&
                    2 != n.alarmsLight[e] &&
                    2 != n.bathRoomAlarmsLight[e]) ||
                    (y[e] = 2),
                ((2 == t && 2 == n.leaveBedLight[e]) ||
                    (2 == t && 2 == n.physiologicalLight[e]) ||
                    (2 == t && 2 == n.alarmsLight[e]) ||
                    (2 == t && 2 == n.bathRoomAlarmsLight[e]) ||
                    (2 == n.leaveBedLight[e] && 2 == n.physiologicalLight[e]) ||
                    (2 == n.leaveBedLight[e] && 2 == n.alarmsLight[e]) ||
                    (2 == n.leaveBedLight[e] && 2 == n.bathRoomAlarmsLight[e]) ||
                    (2 == n.physiologicalLight[e] && 2 == n.alarmsLight[e]) ||
                    (2 == n.physiologicalLight[e] && 2 == n.bathRoomAlarmsLight[e]) ||
                    (2 == n.alarmsLight[e] && 2 == n.bathRoomAlarmsLight[e])) &&
                    (y[e] = 3),
                (3 != t &&
                    3 != n.leaveBedLight[e] &&
                    3 != n.physiologicalLight[e] &&
                    3 != n.alarmsLight[e] &&
                    3 != n.bathRoomAlarmsLight[e]) ||
                    (y[e] = 3),
                ((3 == t && 3 == n.leaveBedLight[e]) ||
                    (3 == t && 3 == n.physiologicalLight[e]) ||
                    (3 == t && 3 == n.alarmsLight[e]) ||
                    (3 == t && 3 == n.bathRoomAlarmsLight[e]) ||
                    (3 == n.leaveBedLight[e] && 3 == n.physiologicalLight[e]) ||
                    (3 == n.leaveBedLight[e] && 3 == n.alarmsLight[e]) ||
                    (3 == n.leaveBedLight[e] && 3 == n.bathRoomAlarmsLight[e]) ||
                    (3 == n.physiologicalLight[e] && 3 == n.alarmsLight[e]) ||
                    (3 == n.physiologicalLight[e] && 3 == n.bathRoomAlarmsLight[e]) ||
                    (3 == n.alarmsLight[e] && 3 == n.bathRoomAlarmsLight[e])) &&
                    (y[e] = 4),
                (t >= 4 ||
                    n.leaveBedLight[e] >= 4 ||
                    n.physiologicalLight[e] >= 4 ||
                    n.alarmsLight[e] >= 4 ||
                    n.bathRoomAlarmsLight[e] >= 4) &&
                    (y[e] = 4),
                y
            ),
        );
        let v = y[y.length - 1],
            _ = Object.assign({}, n, {
                sleepCircle: l,
                leaveBedCircle: Math.round(i),
                physiologicalCircle: Math.round(r),
                alarmsCircle: o,
                toiletTooLongCircle: a,
                CompositeScore: y,
                sleepPercentage: u,
                leaveBedTimes: c,
                leaveBedCurTime: h,
                BpmValue: b,
                RpmValue: f,
                alarmCount: g,
                toiletTooLongTime: A,
                toiletTooLongCur: m,
                CompositeScoreColor: v,
            });
        return (n = _);
    });
};

const Report = (props: Props) => {
    const { businessId, centerId, filter } = props;
    const [sleepReports, setSleepReports] = useState<Report[]>([]);
    const [summaryData, setSummaryData] = useState<SummaryData[]>([]);
    const [finalList, setFinalList] = useState<SummaryData[]>([]);
    const [residentsData, setResidentsData] = useState<any[]>([]);
    const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null });
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const fp = useRef(null);
    const { t: translate } = useTranslation(["common"]);
    
    const fetchData = async () => {
        if (!selectedDates.startDate || !selectedDates.endDate) return;
        const date = `${selectedDates.endDate}~${selectedDates.startDate}`;
        try {
            setIsFetching(true);
            const res = await ReportService.getSummaryReport(
                date,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
                page,
            );
            //@ts-ignore
            const data: Report[] = res.result.data.data;
            //@ts-ignore
            setTotal(res.result.data.count);
            setSleepReports(data);
            setIsFetching(false);
        } catch (error) {
            console.error("Error fetching sleep reports:", error);
            setIsFetching(false);
            // Handle error appropriately
        }
    };

    const fetchResident = async () => {
        try {
            const response = await ResidentService.getAllResidentWithWistronId();
            console.log("response",response.data);
            const list = response.data.map((item: any) => {
                console.log("item",item)
                return {
                    id: item.id,
                    wistronId: item.wistron_id,
                    name: item.name,
                    floor: `${
                        item?.unitRecords?.[0]?.unit?.floor !== undefined && item?.unitRecords?.[0]?.unit?.floor !== null
                                ? item.unitRecords[0].unit.floor + "F" + "-" + item.unitRecords[0].unit.unitNumber
                                : "No Floor - " + (item?.unitRecords?.[0]?.unit?.unitNumber ?? "Unknown Unit")
                    }`,
                    business: item.business,
                    center: item.center,
                };
            });
            console.log("list",list)
            setResidentsData(list);
        } catch (error) {
            console.log("errorrrrrrrrrrrrrrrrrr",error)
        }
    };
    const formatDate = (date: Date) => {
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    const handleSetDate = (date: Date, range: number, self: Instance) => {
        let startDate, endDate;
        if (range === 1) {
            startDate = formatDate(date);
            endDate = formatDate(date);
            self.clear();
            self.setDate([startDate], false, "Y/m/d");
        } else {
            endDate = formatDate(new Date(new Date(date).getTime() - 86400000 * (range - 1)));
            startDate = formatDate(date);
            //update the Flatpickr
            self.clear();
            self.setDate([startDate, endDate], false, "Y/m/d");
        }
        setSelectedDates({ startDate, endDate });
    };
    useEffect(() => {
        // Simulated data fetch or update sleepReports state
        fetchResident();
        let startDate, endDate;
        startDate = formatDate(new Date());
        endDate = formatDate(new Date(new Date(startDate).getTime() - 86400000 * 7));
        setSelectedDates({ startDate, endDate });
    }, []);
    useEffect(() => {
        fetchData();
    }, [selectedDates]);
    useEffect(() => {
        console.log("filter",filter)
    }, [filter]);
    useEffect(() => {
        console.log("sleepReports, residentsData",sleepReports, residentsData)
        if (sleepReports.length && residentsData.length) {
            const summaryData = calculateSummaryData(sleepReports, residentsData);
            setSummaryData(summaryData);
            console.log(summaryData)
        }
    }, [sleepReports, residentsData]);

    useEffect(() => {
        // if (!businessId) return;
    
        let filteredData = summaryData;
    
        if (businessId !== "All") {
            filteredData = filteredData.filter(item => item.business === businessId);
        }
    
        if (centerId !== "All") {
            filteredData = filteredData.filter(item => item.center === centerId);
        }
    
        if (filter) {
            filteredData = filteredData.filter(item => 
                item.name.includes(filter) || item.bed.includes(filter)
            );
        }
    
        console.log(filteredData, 999999);
        setFinalList(filteredData);
    }, [businessId, centerId, summaryData, filter]);

    return (
        <MDBox>
            <MDBox sx={{ padding: "10px" }}>
                <MDTypography variant="h5" color="lightBlue">
                    {translate(`report.period`)}
                    <span style={{ color: "red", verticalAlign: "super" }}>*</span>
                </MDTypography>
                <FormControl sx={{ width: "100%", maxWidth: "205px", position: "relative" }}>
                    <MDBox
                        sx={{
                            py: 1,
                            background: "#E7E7E7",
                            borderRadius: "0px",
                            position: "relative",
                        }}>
                        <Flatpickr
                            ref={fp}
                            value={[selectedDates.startDate, selectedDates.endDate]}
                            disabled={isFetching}
                            options={{
                                mode: "range",
                                locale: {
                                    rangeSeparator: " - ",
                                },
                                maxDate: new Date(),
                                onChange: (date: Date[], currentDateString: string, self: Instance, data: any) => {
                                    if (date.length === 1) {
                                        handleSetDate(date[0], 8, self);
                                    } else {
                                    }
                                },
                            }}
                            render={({ defaultValue }: any, ref: any) => (
                                <MDInput
                                    disabled={isFetching}
                                    sx={{
                                        height: "22px",
                                        width: "100%",
                                        padding: "0 8px",
                                        textAlign: "center",
                                        "& input": {
                                            fontSize: "1rem",
                                            fontWeight: "700",
                                            padding: "0",
                                            "&:before": { border: "0 !important" },
                                        },
                                    }}
                                    defaultValue={defaultValue}
                                    inputRef={ref}
                                />
                            )}
                        />
                    </MDBox>
                </FormControl>
            </MDBox>
            <ReportTable loading={isFetching} list={finalList}  />
        </MDBox>
    );
};

export default Report;
