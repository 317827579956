/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS Base Styles
import typography from "assets/theme/base/typography";

// Declaring props types for Footer
interface Props {
    company?: {
        href: string;
        name: string;
    };
    links?: {
        href: string;
        name: string;
    }[];
    [key: string]: any;
}

export const Footer: React.FC<Props> = ({ company }) => {
    const { href, name } = company;
    const { size } = typography;

    return (
        <MDBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="center"
            alignItems="center"
            mt={5}
            px={1.5}>
            <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                fontSize={size.sm}
                px={1.5}>
                <MDTypography variant="subtitle2" color="lightBlue" sx={{ position: "relative", zIndex: 1 }}>
                    Copyright &copy; 2024 &nbsp;Wecare Dynamics inc.&nbsp; - All right reserved
                </MDTypography>
            </MDBox>
        </MDBox>
    );
};

// Declaring default props for Footer
Footer.defaultProps = {
    company: { href: "/", name: "REL" },
};

export default Footer;
