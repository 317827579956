import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// formik components
import { Formik, Form, FieldArray } from "formik";

// New settings layout schemas for form and form fields
import form from "layouts/settings/schemas/form";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSkeleton from "components/MDSkeleton";
import MDErrorMsg from "components/MDErrorMsg";
import { FormSelect, FormCheckbox, FormTimePicker } from "components/MDFormField";
import AddArrayButton from "components/MDButton/addArrayButton";
import RemoveArrayButton from "components/MDButton/removeArrayButton";
import FormLayout from "examples/LayoutContainers/FormLayout";

// Types
import { NotificationSettings, ResidentialAlarmSetting } from "types/setting-schema";

// Service
import ResidentialAlarmService from "services/residentialAlarm.service";

// Types
import { RoomType } from "types/enums";

// Helpers
import { isNil, isNotNil } from "helpers/utils";
import { getFormKeys, getCamelCase } from "helpers/formUtil";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro";

// i18n
import { useTranslation } from "react-i18next";

export const ResidentialAlarmForm: React.FC = () => {
    const navigate = useNavigate();
    const [residentialAlarm, setResidentialAlarm] = useState<ResidentialAlarmSetting | null>(null);
    const { formId, formField } = form;
    const [error, setError] = useState<string | null>(null);
    const { turnSetting, heartRate, respirationRate, temperature, leaving, lineSetting } = formField.residentialAlarm;
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        const fetchResidentialAlarm = async () => {
            const res = await ResidentialAlarmService.getResidentialAlarm();
            if (res.success) {
                setResidentialAlarm(res.data[0]);
            }
        };
        fetchResidentialAlarm();
    }, []);

    const submitForm = async (values: any, actions: any) => {
        setError(null);
        let res = await handleUpdateAlarm(values);
        if (res.success) {
            navigate("/dashboard");
        } else {
            setError(res.error);
        }

        actions.setSubmitting(false);
    };

    const handleUpdateAlarm = async (residentialAlarm: ResidentialAlarmSetting) => {
        return ResidentialAlarmService.updateResidentialAlarm(residentialAlarm);
    };

    const renderAlarmTitle = (title: string) => {
        return (
            <MDBox paddingBottom="10px">
                <MDTypography variant="h2">{translate(`setting.alarms.subtitle.${title}`)}</MDTypography>
            </MDBox>
        );
    };

    if (isNil(residentialAlarm)) return <MDSkeleton />;
    const timeH = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12","13","14","15","16","17","18","19","20","21","22","23","23"];
    const timeM = ["00", "10", "20", "30", "40", "50"];
    const period = ["30", "40", "50", "60", "70", "80", "90", "100", "110", "120"];
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                <Grid item xs={12}>
                    <Formik initialValues={{ ...residentialAlarm }} onSubmit={submitForm}>
                        {({ values, isSubmitting }) => (
                            <Form id={formId} autoComplete="off">
                                <FormLayout>
                                    <MDBox mt={1.625}>
                                        <hr />
                                        <MDBox>
                                            {renderAlarmTitle("vital")}
                                            <Grid container mb={3} spacing={2}>
                                                <Grid item md={2.5} xs={12} display="flex" alignItems="center">
                                                    <MDTypography variant="h5">
                                                        {translate("setting.alarms.notifyRangeSetting")}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={3} display="flex" alignItems="center">
                                                    <Grid
                                                        container
                                                        display="flex"
                                                        alignItems="center"
                                                        columnSpacing={1}>
                                                        <Grid item xs={2} md={4}>
                                                            <FormSelect
                                                                {...getFormKeys(lineSetting, "")}
                                                                options={[5, 10, 20, 30].map((num: number) => ({
                                                                    option: num,
                                                                    value: num,
                                                                }))}
                                                                value={values.lineSetting}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <MDTypography variant="body2">
                                                                {translate("setting.alarms.minPerTime")}
                                                            </MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container mb={3} spacing={2} display="flex" alignItems="center">
                                                <Grid item md={2.5} xs={12} display="flex" alignItems="center">
                                                    <MDTypography variant="h5">
                                                        {translate("setting.alarms.rrRange")}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={4} display="flex" alignItems="center">
                                                    <FormCheckbox
                                                        {...getFormKeys(
                                                            respirationRate.hasAlarmNotice,
                                                            translate(`setting.alarms.alarm`),
                                                        )}
                                                        checked={values.respirationRate.hasAlarmNotice}
                                                    />
                                                    <FormCheckbox
                                                        {...getFormKeys(
                                                            respirationRate.hasLine,
                                                            translate(`setting.alarms.instantMsg`),
                                                        )}
                                                        checked={values.respirationRate.hasLine}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={5}
                                                    md={2}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between">
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.durationTime")}
                                                    </MDTypography>
                                                    <FormSelect
                                                        {...getFormKeys(respirationRate.period, "")}
                                                        options={period.map((num: string) => ({
                                                            option: num,
                                                            value: num,
                                                        }))}
                                                        value={values.respirationRate.period}
                                                    />
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.sec")}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    md={2}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between">
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.outOfRange")}
                                                    </MDTypography>
                                                    <FormSelect
                                                        {...getFormKeys(respirationRate.max, "")}
                                                        options={[10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map(
                                                            (num: number) => ({
                                                                option: num,
                                                                value: num,
                                                            }),
                                                        )}
                                                        value={values.respirationRate.max}
                                                    />
                                                    <MDTypography variant="body2">rpm</MDTypography>
                                                </Grid>
                                            </Grid>
                                            <Grid container mb={3} spacing={2} display="flex" alignItems="center">
                                                <Grid item xs={12} md={2.5} display="flex" alignItems="center">
                                                    <MDTypography variant="h5">
                                                        {translate("setting.alarms.hrRange")}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={4} display="flex" alignItems="center">
                                                    <FormCheckbox
                                                        {...getFormKeys(
                                                            heartRate.hasAlarmNotice,
                                                            translate(`setting.alarms.alarm`),
                                                        )}
                                                        checked={values.heartRate.hasAlarmNotice}
                                                    />
                                                    <FormCheckbox
                                                        {...getFormKeys(
                                                            heartRate.hasLine,
                                                            translate(`setting.alarms.instantMsg`),
                                                        )}
                                                        checked={values.heartRate.hasLine}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={5}
                                                    md={2}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between">
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.durationTime")}
                                                    </MDTypography>
                                                    <FormSelect
                                                        {...getFormKeys(heartRate.period, "")}
                                                        options={period.map((num: string) => ({
                                                            option: num,
                                                            value: num,
                                                        }))}
                                                        value={values.heartRate.period}
                                                    />
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.sec")}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    md={3}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between">
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.outOfRange")}
                                                    </MDTypography>
                                                    <FormSelect
                                                        {...getFormKeys(heartRate.min, "")}
                                                        options={[30, 40, 50, 60, 70].map((num: number) => ({
                                                            option: num,
                                                            value: num,
                                                        }))}
                                                        value={1}
                                                    />
                                                    <MDTypography variant="body2">~</MDTypography>
                                                    <FormSelect
                                                        {...getFormKeys(heartRate.max, "")}
                                                        options={[80, 90, 100, 110, 120, 130].map((num: number) => ({
                                                            option: num,
                                                            value: num,
                                                        }))}
                                                        value={1}
                                                    />
                                                    <MDTypography variant="body2">rpm</MDTypography>
                                                </Grid>
                                            </Grid>
                                            <Grid container mb={3} spacing={2} display="flex" alignItems="center">
                                                <Grid item xs={12} md={2.5} display="flex" alignItems="center">
                                                    <MDTypography variant="h5">
                                                        {translate("setting.alarms.tempRange")}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={4} display="flex" alignItems="center">
                                                    <FormCheckbox
                                                        disabled
                                                        {...getFormKeys(
                                                            temperature.hasAlarmNotice,
                                                            translate(`setting.alarms.alarm`),
                                                        )}
                                                        // checked={value.alarm}
                                                    />
                                                    <FormCheckbox
                                                        disabled
                                                        {...getFormKeys(
                                                            temperature.hasLine,
                                                            translate(`setting.alarms.instantMsg`),
                                                        )}
                                                        // checked={value.alarm}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    md={3}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between">
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.temp")}
                                                    </MDTypography>
                                                    <FormSelect
                                                        {...getFormKeys(temperature.min, "")}
                                                        disabled
                                                        options={[15, 30, 45, 60, 90, 120, 240, 360].map(
                                                            (num: number) => ({
                                                                option: num,
                                                                value: num,
                                                            }),
                                                        )}
                                                        value={1}
                                                    />
                                                    <MDTypography variant="body2">~</MDTypography>
                                                    <FormSelect
                                                        {...getFormKeys(temperature.max, "")}
                                                        disabled
                                                        options={[15, 30, 45, 60, 90, 120, 240, 360].map(
                                                            (num: number) => ({
                                                                option: num,
                                                                value: num,
                                                            }),
                                                        )}
                                                        value={1}
                                                    />
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.cel")}
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                        <hr />
                                        <MDBox>
                                            {renderAlarmTitle("bed")}
                                            <Grid container mb={3} spacing={2}>
                                                <Grid item xs={4} md={2.5} display="flex" alignItems="center">
                                                    <MDTypography variant="h5">
                                                        {translate("setting.alarms.notifyRangeSetting")}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={8} md={3} display="flex" alignItems="center">
                                                    <Grid
                                                        container
                                                        display="flex"
                                                        alignItems="center"
                                                        columnSpacing={1}>
                                                        <Grid item xs={2} md={4}>
                                                            <FormSelect
                                                                {...getFormKeys(lineSetting, "")}
                                                                options={[5, 10, 20, 30].map((num: number) => ({
                                                                    option: num,
                                                                    value: num,
                                                                }))}
                                                                value={values.lineSetting}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={10} md={4}>
                                                            <MDTypography variant="body2">
                                                                {translate("setting.alarms.minPerTime")}
                                                            </MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container mb={3} spacing={2} display="flex" alignItems="center">
                                                <Grid item xs={12} md={2.5} display="flex" alignItems="center">
                                                    <MDTypography variant="h5">
                                                        {translate("setting.alarms.notInBed")}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={4} display="flex" alignItems="center">
                                                    <FormCheckbox
                                                        {...getFormKeys(
                                                            leaving.hasAlarmNotice,
                                                            translate(`setting.alarms.alarm`),
                                                        )}
                                                        checked={values.leaving.hasAlarmNotice}
                                                    />
                                                    <FormCheckbox
                                                        {...getFormKeys(
                                                            leaving.hasLine,
                                                            translate(`setting.alarms.instantMsg`),
                                                        )}
                                                        checked={values.leaving.hasLine}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={5.5}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between">
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.notInBedTime")}
                                                    </MDTypography>
                                                    <FormSelect
                                                        {...getFormKeys(leaving.st, "")}
                                                        disabled={!values.leaving.hasAlarmNotice||!values.leaving.hasLine}
                                                        options={timeH.map((num: string) => ({
                                                            option: num,
                                                            value: num,
                                                        }))}
                                                        value={values.leaving.st}
                                                    />
                                                    :
                                                    <FormSelect
                                                        {...getFormKeys(leaving.sm, "")}
                                                        disabled={!values.leaving.hasAlarmNotice||!values.leaving.hasLine}
                                                        options={timeM.map((num: string) => ({
                                                            option: num,
                                                            value: num,
                                                        }))}
                                                        value={values.leaving.sm}
                                                    />
                                                    ~
                                                    <FormSelect
                                                        {...getFormKeys(leaving.et, "")}
                                                        disabled={!values.leaving.hasAlarmNotice||!values.leaving.hasLine}
                                                        options={timeH.map((num: string) => ({
                                                            option: num,
                                                            value: num,
                                                        }))}
                                                        value={values.leaving.et}
                                                    />
                                                    :
                                                    <FormSelect
                                                        {...getFormKeys(leaving.em, "")}
                                                        disabled={!values.leaving.hasAlarmNotice||!values.leaving.hasLine}
                                                        options={timeM.map((num: string) => ({
                                                            option: num,
                                                            value: num,
                                                        }))}
                                                        value={values.leaving.em}
                                                    />
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.durationTime")}
                                                    </MDTypography>
                                                    <FormSelect
                                                        {...getFormKeys(leaving.period, "")}
                                                        disabled={!values.leaving.hasAlarmNotice||!values.leaving.hasLine}
                                                        options={[2,3,5,10,15,20,25,30].map((num: number) => ({
                                                            option: `${num} ${translate("setting.alarms.min")}`,
                                                            value: num*60,
                                                        }))}
                                                        value={values.leaving.period}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid container mb={3} spacing={2} display="flex" alignItems="center">
                                                <Grid item xs={12} md={2.5} display="flex" alignItems="center">
                                                    <MDTypography variant="h5">
                                                        {translate("setting.alarms.turnOver")}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={4} display="flex" alignItems="center">
                                                    <FormCheckbox
                                                        disabled
                                                        {...getFormKeys(
                                                            turnSetting.hasAlarmNotice,
                                                            translate(`setting.alarms.alarm`),
                                                        )}
                                                        // checked={value.alarm}
                                                    />
                                                    <FormCheckbox
                                                        disabled
                                                        {...getFormKeys(
                                                            turnSetting.hasLine,
                                                            translate(`setting.alarms.instantMsg`),
                                                        )}
                                                        // checked={value.alarm}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between">
                                                    <FormSelect
                                                        {...getFormKeys(turnSetting.turnSetting, "")}
                                                        disabled
                                                        options={[15, 30, 45, 60, 90, 120, 240, 360].map(
                                                            (num: number) => ({
                                                                option: num,
                                                                value: num,
                                                            }),
                                                        )}
                                                        
                                                    />
                                                    <MDTypography variant="body2">
                                                        {translate("setting.alarms.turnOverTime")}
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                    </MDBox>
                                    {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                                    <MDBox display="flex" justifyContent="end" mt={2} width="100%">
                                        <MDButton
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="gradient"
                                            circular={false}>
                                            {translate("general.button.save")}
                                        </MDButton>
                                    </MDBox>
                                </FormLayout>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};
