import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// formik components
import { Formik, Form, FieldArray } from "formik";

// New settings layout schemas for form and form fields
import form from "layouts/settings/schemas/form";
import useValidationSchema from "layouts/settings/schemas/validations/resident";

// Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSkeleton from "components/MDSkeleton";
import MDErrorMsg from "components/MDErrorMsg";
import MDAvatar from "components/MDAvatar";
import { FormField, FormSelect, FormSwitch, FormDatePicker, FormCheckbox } from "components/MDFormField";
import { PhoneField, PhoneArrayField } from "components/MDFormField/phoneField";
import FormTitle from "components/MDFormField/title";
import AddArrayButton from "components/MDButton/addArrayButton";
import SubmitButtons from "components/MDButton/submitButton";
import EditButton from "components/MDButton/editButton";
import RemoveArrayButton from "components/MDButton/removeArrayButton";
import DeleteConfirmPop from "components/MDFormField/deleteConfirm";
import FormLayout from "examples/LayoutContainers/FormLayout";
import { UploadableImage } from "components/MDFormField/uploadableImage";

// Types
import { Center, Unit, Resident } from "types/setting-schema";
import { FormOperation, FormType } from "types/enums";

// Service
import CenterService from "services/center.service";
import ResidentService from "services/resident.service";

// Helper
import { isNil, isNotNil } from "helpers/utils";
import {
    isEditDisabled,
    NOT_SELECTED,
    getFormKeys,
    getCountryCode,
    getCenterByName,
    uploadFile,
    deleteFile,
    fileRead,
} from "helpers/formUtil";
import dayjs from "dayjs";
import defaultAvatar from "assets/images/defaultAvatar.svg";

// i18n
import { useTranslation } from "react-i18next";
import { object } from "yup";

interface AvailableUnitsProps {
    _id: string;
    name: string;
    business: string;
    units: Unit[];
}

interface Props {
    operation: FormOperation;
    businessId?: string;
    resident?: Resident;
    callback: (updated: boolean, isDelete?: boolean) => void;
}

export const ResidentForm: React.FC<Props> = ({ operation: ops, businessId, resident: initResident, callback }) => {
    const navigate = useNavigate();
    const [operation, setOperation] = useState<FormOperation>(ops);
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [moveoutConfirm, setMoveoutConfirm] = useState<boolean>(false);
    const [centerData, setCenterData] = useState<Center[] | null>(null);
    const [unitsByBusiness, setUnitsByBusiness] = useState<AvailableUnitsProps[] | null>(null); // stores available unit only
    const { t: translate } = useTranslation(["common"]);
    const latestUnit = initResident?.unitRecords?.at(-1);
    const notSelectedOption = { option: translate(`general.state.notSelected`), value: NOT_SELECTED };
    const validations = useValidationSchema();
    const { formId, formField } = form;
    const {
        avatarUrl,
        name,
        number,
        gender,
        dateOfBirth,
        address,
        phone,
        email,
        emergency,
        center,
        unit,
        floor,
        preferredLanguage,
        diabetes,
    } = formField.resident;

    const defaultResident: Resident = {
        avatarUrl: null,
        name: "",
        number: "",
        gender: "male",
        dateOfBirth: new Date().toISOString().substring(0, 10),
        phone: {
            countryCode: getCountryCode(translate("lang")),
            number: "",
        },
        email: "",
        address: "",
        emergency: [
            {
                name: "",
                phone: {
                    countryCode: getCountryCode(translate("lang")),
                    number: "",
                },
                email: "",
                relation: "son",
                notification: false,
            },
        ],
        center: {
            name: NOT_SELECTED,
        },
        unit: NOT_SELECTED,
        floor: NOT_SELECTED,
        preferredLanguage: "en",
        diabetes: false,
    };

    useEffect(() => {
        const fetchCenters = async () => {
            const res = await CenterService.getAllCenters(businessId);
            if (res.success) {
                fetchUnits(res.data);
            }
        };

        const fetchUnits = async (centerData: Center[]) => {
            const res = await ResidentService.getAvailableUnits(businessId);
            if (res.success) {
                setUnitsByBusiness(res.data);
                const data = await filterCenterData(centerData, res.data);
                setCenterData(data);
            }
        };

        // Only display those centers which still have available unit
        const filterCenterData = async (centerData: Center[], ut: AvailableUnitsProps[]) => {
            const result = centerData.filter((item) => {
                return ut.find((availableUt) => availableUt.name === item.name);
            });
            return isNil(result) ? [] : result;
        };

        if (isNotNil(businessId) && !isEditDisabled(operation)) {
            fetchCenters();
        }
    }, [businessId, operation]);

    const handleResult = (res: any, isDelete?: boolean) => {
        if (res.success) {
            if (isNotNil(callback)) {
                callback(true, isDelete);
            } else {
                navigate("/dashboard");
            }
        } else {
            setError(res.error);
        }
    };

    const submitForm = async (values: any, actions: any) => {
        setError(null);
        const { avatarUrl, center, unit, floor, unitRecords, ...updatedValue } = values;
        let info = updatedValue;

        // If previewImage has not changed means user does not choose an image
        if (isNotNil(avatarUrl) && avatarUrl !== initResident?.avatarUrl) {
            const path = `${centerData[0].business.name}/${businessId}/${FormType.RESIDENT}`;

            await deleteFile(initResident?.avatarUrl);
            const uploadRes = await uploadFile(path, avatarUrl);
            if (isNotNil(uploadRes)) {
                info.avatarUrl = uploadRes;
            }
        }

        if (values.center.name !== NOT_SELECTED && values.center.name !== initResident?.center?.name) {
            // Store center info
            const { _id } = getCenterByName(centerData, values.center.name);

            info.center = _id;

            // Store unit info
            if (values.unit !== NOT_SELECTED) {
                info.unit = values.unit;
            }
        }

        let res;
        if (operation === FormOperation.CREATE) {
            res = await handleCreateResident(info);
        } else {
            res = await handleUpdateResident(info);
        }

        handleResult(res);

        actions.setSubmitting(false);
    };

    const handleCreateResident = async (resident: Resident) => {
        return ResidentService.createResident(businessId, resident);
    };

    const handleUpdateResident = async (resident: Resident) => {
        return ResidentService.updateSingleResident(initResident._id, resident);
    };

    const handleDeleteResident = async () => {
        await deleteFile(initResident?.avatarUrl);
        const res = await ResidentService.deleteSingleResident(initResident._id);
        handleResult(res, true);
    };

    const moveOut = async () => {
        const res = await ResidentService.moveOutFromUnit(initResident._id, latestUnit.unit._id);
        handleResult(res);
    };

    const getFloorSet = (ut: AvailableUnitsProps) => {
        // Can have multiple units in a same floor. Use set to get unique value
        const floorSet = [...new Set(ut.units.map((item: Unit) => item.floor))];
        return floorSet.sort().map((item: string) => ({ option: item, value: item }));
    };

    const getAvailableUnit = (isCentralized: boolean, valueFloor: string, ut: AvailableUnitsProps) => {
        if (isCentralized) {
            if (valueFloor !== NOT_SELECTED) {
                // if floor has a value, should filter to those units in this specific floor
                return ut.units
                    .filter((unit: Unit) => unit.floor === valueFloor)
                    .map((item) => ({ option: item.unitNumber, value: item._id }));
            } else {
                return ut.units.map((item) => ({ option: item.unitNumber, value: item._id }));
            }
        } else {
            return ut.units.map((item) => ({ option: item.unitNumber, value: item._id }));
        }
    };

    const checkAvailableUnit = () => {
        console.log(unitsByBusiness);
        return isNotNil(unitsByBusiness) && unitsByBusiness.length === 0;
    };

    const isAssignedToUnit = () => {
        // if true, means still in the last unit and not moved out
        return isNotNil(latestUnit) && isNil(latestUnit?.moveOutDate);
    };

    const renderCenter = (centerName: string, handleChange: any, setFieldValue: any) => {
        return (
            <Grid item xs={12} sm={4}>
                <FormSelect
                    options={
                        centerData?.length > 0
                            ? [
                                  notSelectedOption,
                                  ...centerData?.map((center) => ({
                                      option: center.name,
                                      value: center.name,
                                  })),
                              ]
                            : [notSelectedOption]
                    }
                    {...getFormKeys(center.name, translate(`setting.center.name`))}
                    value={centerName}
                    onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue("floor", NOT_SELECTED);
                        setFieldValue("unit", NOT_SELECTED);
                    }}
                    isDisplayMode={
                        isEditDisabled(operation) ||
                        (initResident?.unitRecords?.length > 0 && isNil(latestUnit?.moveOutDate))
                    }
                />
            </Grid>
        );
    };

    const renderUnits = (centerName: string, valueFloor: string, handleChange: any, setFieldValue: any) => {
        if (operation === FormOperation.CREATE || (operation === FormOperation.UPDATE && !isAssignedToUnit())) {
            const isCentralized = getCenterByName(centerData, centerName)?.isCentralized;
            // get units by center name
            const ut = unitsByBusiness?.find((unit) => unit.name === centerName);
            if (isNil(ut)) return <></>;

            // get floors of all units in this center
            const floors: { option: string; value: string }[] = getFloorSet(ut);
            // get unit by floor
            const units: { option: string; value: string }[] = getAvailableUnit(isCentralized, valueFloor, ut);

            return (
                <>
                    {isCentralized ? (
                        <>
                            <Grid item xs={12} sm={2}>
                                <FormSelect
                                    options={[notSelectedOption, ...floors]}
                                    {...getFormKeys(floor, translate(`setting.unit.floor`))}
                                    onChange={(e: any) => {
                                        handleChange(e);
                                        setFieldValue("unit", NOT_SELECTED);
                                    }}
                                />
                            </Grid>
                            {valueFloor !== NOT_SELECTED && (
                                <Grid item xs={12} sm={2}>
                                    <FormSelect
                                        options={[notSelectedOption, ...units]}
                                        {...getFormKeys(unit, translate(`setting.unit.unitNumber`))}
                                    />
                                </Grid>
                            )}
                        </>
                    ) : (
                        <Grid item xs={12} sm={2}>
                            <FormSelect
                                options={[notSelectedOption, ...units]}
                                {...getFormKeys(unit, translate(`setting.unit.unitNumber`))}
                            />
                        </Grid>
                    )}
                </>
            );
        }

        // If never assigned to any unit or already moved out, don't render
        if (initResident?.unitRecords?.length > 0 && isNil(latestUnit?.moveOutDate) && isNotNil(latestUnit?.unit)) {
            return (
                <>
                    {isNotNil(latestUnit?.unit?.floor) && (
                        <Grid item xs={12} sm={2}>
                            <FormField
                                {...getFormKeys(floor, translate(`setting.unit.floor`))}
                                value={latestUnit.unit.floor}
                                isDisplayMode={true}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={2}>
                        <FormField
                            {...getFormKeys(unit, translate(`setting.unit.unitNumber`))}
                            value={latestUnit?.unit?.unitNumber}
                            isDisplayMode={true}
                        />
                    </Grid>
                    {!isEditDisabled(operation) && (
                        <Grid item xs={12} sm={3} alignSelf="center">
                            <MDButton onClick={() => setMoveoutConfirm(true)} circular={false} variant="gradient">
                                {translate("setting.resident.moveout")}
                            </MDButton>
                        </Grid>
                    )}
                </>
            );
        }
    };

    const removePop = () => {
        if (deleteConfirm) {
            return (
                <DeleteConfirmPop
                    title={`${translate("general.popup.delete")}`}
                    handleDelete={handleDeleteResident}
                    setDeleteConfirm={setDeleteConfirm}
                />
            );
        } else if (moveoutConfirm) {
            return (
                <DeleteConfirmPop
                    title={`${translate("general.popup.moveout")}`}
                    note={`${translate("general.popup.cutoffNotice")}`}
                    handleDelete={moveOut}
                    setDeleteConfirm={setMoveoutConfirm}
                />
            );
        }
    };

    const defaultAvatarComponent = () => {
        return <MDAvatar src={defaultAvatar} alt="Default Svg" size="xl" shadow="sm" />;
    };
    console.log(operation)
    if (!isEditDisabled(operation) && isNil(centerData)) return <MDSkeleton />;
    return (
        <>
            {(deleteConfirm || moveoutConfirm) && removePop()}

            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{ ...defaultResident, ...initResident }}
                        validationSchema={validations.resident[0]}
                        onSubmit={submitForm}>
                        {({ values, errors, touched, dirty, setFieldValue, handleChange, isSubmitting }) => {
                            console.log(values)
                            return (
                                <Form id={formId} autoComplete="off" noValidate>
                                    <FormLayout
                                        header={`${translate(`general.operation.${operation}`)}${
                                            operation === FormOperation.CREATE ? translate(`general.state.new`) : ""
                                        }${translate(`general.type.${FormType.RESIDENT}`)}`}
                                        name={operation !== FormOperation.CREATE ? initResident?.name : ""}>
                                        {operation === FormOperation.VIEW && <EditButton setOperation={setOperation} />}
                                        <MDBox mt={1.625}>
                                            <MDBox mb={5}>
                                                <Grid container spacing={3} mb={3} alignItems="center">
                                                    <Grid item xs={12} sm={3}>
                                                        <UploadableImage
                                                            imageUrl={fileRead(values.avatarUrl)}
                                                            defaultElement={defaultAvatarComponent()}
                                                            bgColor="white"
                                                            {...getFormKeys(avatarUrl, "")}
                                                            onChange={(e) => {
                                                                setFieldValue("avatarUrl", e.target.files[0]);
                                                            }}
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormField
                                                            {...getFormKeys(name, translate(`setting.resident.name`))}
                                                            value={values.name}
                                                            error={errors.name && touched.name}
                                                            success={values.name.length > 0 && !errors.name}
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4}>
                                                        <FormField
                                                            {...getFormKeys(
                                                                number,
                                                                translate(`setting.resident.number`),
                                                            )}
                                                            value={
                                                                isEditDisabled(operation) && values.number.length === 0
                                                                    ? "N/A"
                                                                    : values.number
                                                            }
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} mb={3}>
                                                    <Grid item xs={6} sm={6}>
                                                        <FormSwitch
                                                            {...getFormKeys(
                                                                gender,
                                                                translate(`setting.resident.gender`),
                                                            )}
                                                            options={[
                                                                translate(`setting.resident.male`),
                                                                translate(`setting.resident.female`),
                                                            ]}
                                                            // If is editable, value = value. Otherwise, display localized value
                                                            value={
                                                                !isEditDisabled(operation)
                                                                    ? values.gender
                                                                    : values.gender === "male"
                                                                    ? translate(`setting.resident.male`)
                                                                    : translate(`setting.resident.female`)
                                                            }
                                                            checked={values.gender !== "male"}
                                                            onChange={() => {
                                                                setFieldValue(
                                                                    "gender",
                                                                    values.gender === "male" ? "female" : "male",
                                                                );
                                                            }}
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} alignSelf="center">
                                                        <FormDatePicker 
                                                            {...getFormKeys(
                                                                dateOfBirth,
                                                                translate(`setting.resident.dateOfBirth`),
                                                            )}
                                                            value={dayjs(values.dateOfBirth)}
                                                            success={dayjs(values.dateOfBirth) && !errors.dateOfBirth}
                                                            error={errors.dateOfBirth}
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} mb={3}>
                                                    <Grid item xs={12}>
                                                        <FormField
                                                            {...getFormKeys(
                                                                address,
                                                                translate(`setting.resident.address`),
                                                            )}
                                                            value={values.address}
                                                            error={errors.address && touched.address}
                                                            success={values.address.length > 0 && !errors.address}
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} mb={3}>
                                                    <PhoneField
                                                        {...{
                                                            phoneValue: values.phone,
                                                            errors,
                                                            touched,
                                                            field: phone,
                                                            handleChange,
                                                            label: translate(`setting.resident.phone`),
                                                            isEditDisabled: isEditDisabled(operation),
                                                        }}
                                                    />
                                                    <Grid item xs={12} sm={6}>
                                                        <FormField
                                                            {...getFormKeys(email, translate(`setting.resident.email`))}
                                                            value={
                                                                isEditDisabled(operation) && values.email.length === 0
                                                                    ? "None"
                                                                    : values.email
                                                            }
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={3}>
                                                        <FormSelect
                                                            options={[
                                                                {
                                                                    option: translate(`en`),
                                                                    value: "en",
                                                                },
                                                                {
                                                                    option: translate(`tw`),
                                                                    value: "tw",
                                                                },
                                                            ]}
                                                            value={values.preferredLanguage}
                                                            {...getFormKeys(
                                                                preferredLanguage,
                                                                translate(`setting.resident.preferredLanguage`),
                                                            )}
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <FormSelect
                                                            options={[
                                                                {
                                                                    option: translate(`general.popup.no`),
                                                                    value: false,
                                                                },
                                                                {
                                                                    option: translate(`general.popup.yes`),
                                                                    value: true,
                                                                },
                                                            ]}
                                                            value={
                                                                !isEditDisabled(operation)
                                                                    ? values.diabetes
                                                                    : values.diabetes
                                                                    ? translate(`general.popup.yes`)
                                                                    : translate(`general.popup.no`)
                                                            }
                                                            {...getFormKeys(
                                                                diabetes,
                                                                translate(`setting.resident.diabetes`),
                                                            )}
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </MDBox>

                                            {/* Emergency Array */}
                                            {((isEditDisabled(operation) && values.emergency.length > 0) ||
                                                !isEditDisabled(operation)) && (
                                                <FieldArray
                                                    name="emergency"
                                                    render={(arrayHelpers) => (
                                                        <MDBox my={3}>
                                                            <MDBox
                                                                display="flex"
                                                                justifyContent="space-between"
                                                                alignItems="center">
                                                                <FormTitle
                                                                    content={`${translate(
                                                                        `setting.title.emergencyContact`,
                                                                    )}`}
                                                                />
                                                                {!isEditDisabled(operation) && (
                                                                    <AddArrayButton
                                                                        content={`${translate(
                                                                            `general.button.addContact`,
                                                                        )}`}
                                                                        ary={arrayHelpers as any}
                                                                        obj={{
                                                                            name: "",
                                                                            phone: {
                                                                                countryCode: "+1",
                                                                                number: "",
                                                                            },
                                                                            email: "",
                                                                            relation: "son",
                                                                            notification: false,
                                                                        }}
                                                                    />
                                                                )}
                                                            </MDBox>

                                                            {values.emergency &&
                                                                values.emergency.map((em, index) => {
                                                                    const emergencyEle = `emergency.${index}`;
                                                                    return (
                                                                        <MDBox key={index} mt={1} mb={5}>
                                                                            <Grid
                                                                                container
                                                                                alignItems={
                                                                                    !isEditDisabled(operation)
                                                                                        ? "end"
                                                                                        : "start"
                                                                                }
                                                                                columnSpacing={2}>
                                                                                <Grid item xs={0.5}>
                                                                                    <MDTypography variant="h6">
                                                                                        {index + 1}.
                                                                                    </MDTypography>
                                                                                </Grid>
                                                                                <Grid item xs={8} sm={3}>
                                                                                    <FormField
                                                                                        {...getFormKeys(
                                                                                            emergency.name,
                                                                                            translate(
                                                                                                `setting.resident.emergencyContact`,
                                                                                            ),
                                                                                        )}
                                                                                        name={`${emergencyEle}.name`}
                                                                                        value={em.name}
                                                                                        success={em.name.length > 0}
                                                                                        isDisplayMode={isEditDisabled(
                                                                                            operation,
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <FormSelect
                                                                                        {...getFormKeys(
                                                                                            emergency.relation,
                                                                                            translate(
                                                                                                `setting.resident.emergencyContactRelation`,
                                                                                            ),
                                                                                        )}
                                                                                        name={`${emergencyEle}.relation`}
                                                                                        value={
                                                                                            isEditDisabled(operation)
                                                                                                ? translate(
                                                                                                      `setting.resident.relation.${em.relation.toLocaleLowerCase()}`,
                                                                                                  )
                                                                                                : em.relation
                                                                                        }
                                                                                        options={[
                                                                                            "son",
                                                                                            "daughter",
                                                                                            "father",
                                                                                            "mother",
                                                                                            "friend",
                                                                                            "relative",
                                                                                            "other",
                                                                                        ].map((item) => ({
                                                                                            option: translate(
                                                                                                `setting.resident.relation.${item}`,
                                                                                            ),
                                                                                            value: item,
                                                                                        }))}
                                                                                        isDisplayMode={isEditDisabled(
                                                                                            operation,
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                <PhoneArrayField
                                                                                    {...{
                                                                                        phoneValue: em.phone,
                                                                                        field: emergency.phone,
                                                                                        handleChange,
                                                                                        label: translate(
                                                                                            `setting.resident.emergencyContactPhone`,
                                                                                        ),
                                                                                        nameIndex: `${emergencyEle}.phone`,
                                                                                        isEditDisabled:
                                                                                            isEditDisabled(operation),
                                                                                    }}
                                                                                />
                                                                            </Grid>

                                                                            <Grid
                                                                                container
                                                                                columnSpacing={2}
                                                                                alignItems="end"
                                                                                my={2}>
                                                                                <Grid item xs={0.5}></Grid>
                                                                                <Grid item xs={8} md={5}>
                                                                                    <FormCheckbox
                                                                                        {...getFormKeys(
                                                                                            emergency.notification,
                                                                                            translate(
                                                                                                `setting.resident.notification`,
                                                                                            ),
                                                                                        )}
                                                                                        name={`${emergencyEle}.notification`}
                                                                                        checked={em.notification}
                                                                                        isDisplayMode={isEditDisabled(
                                                                                            operation,
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={5}
                                                                                    md={4.5}
                                                                                    ml={{ xs: 3, md: 0 }}>
                                                                                    <FormField
                                                                                        {...getFormKeys(
                                                                                            emergency.email,
                                                                                            translate(
                                                                                                `setting.resident.emergencyContactEmail`,
                                                                                            ),
                                                                                        )}
                                                                                        name={`${emergencyEle}.email`}
                                                                                        value={
                                                                                            isEditDisabled(operation) &&
                                                                                            em.email.length === 0
                                                                                                ? "None"
                                                                                                : em.email
                                                                                        }
                                                                                        isDisplayMode={isEditDisabled(
                                                                                            operation,
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                {values.emergency.length > 1 &&
                                                                                    !isEditDisabled(operation) && (
                                                                                        <Grid item xs={2}>
                                                                                            <RemoveArrayButton
                                                                                                ary={
                                                                                                    arrayHelpers as any
                                                                                                }
                                                                                                index={index}
                                                                                            />
                                                                                        </Grid>
                                                                                    )}
                                                                            </Grid>
                                                                        </MDBox>
                                                                    );
                                                                })}
                                                        </MDBox>
                                                    )}
                                                />
                                            )}
                                            {/* Display this section if:
                                                1. is in view mode
                                                2. is in create mode and there's at least one available unit
                                                3. is in update mode and has assigned to an unit or there's at least one available unit
                                            */}
                                            {(operation === FormOperation.CREATE && checkAvailableUnit()) ||
                                            (operation === FormOperation.UPDATE &&
                                                !isAssignedToUnit() &&
                                                checkAvailableUnit()) ? (
                                                <></>
                                            ) : (
                                                <>
                                                    <Divider sx={{ backgroundColor: "#000" }} />
                                                    {operation !== FormOperation.VIEW && (
                                                        <FormTitle
                                                            content={`${translate(`setting.title.chooseUnit`)}`}
                                                        />
                                                    )}
                                                    <Grid container spacing={3}>
                                                        {renderCenter(values.center.name, handleChange, setFieldValue)}
                                                        {values.center.name !== NOT_SELECTED &&
                                                            renderUnits(
                                                                values.center.name,
                                                                values.floor,
                                                                handleChange,
                                                                setFieldValue,
                                                            )}
                                                    </Grid>
                                                    {operation !== FormOperation.VIEW && (
                                                        <MDBox mt={1}>
                                                            <MDTypography variant="caption" fontWeight="regular">
                                                                Note: {translate(`general.popup.cutoffNotice`)}
                                                            </MDTypography>
                                                        </MDBox>
                                                    )}
                                                </>
                                            )}
                                        </MDBox>
                                        {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                                        <SubmitButtons
                                            disable={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                                            operation={operation}
                                            setDeleteConfirm={setDeleteConfirm}
                                        />
                                    </FormLayout>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};
