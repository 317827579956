import { Theme } from "@mui/material/styles";

export const tabContainer = (theme: Theme, ownerState: any) => {
    const { isIcon } = ownerState;
    const breakpoints = theme.breakpoints;
    const minWidth = breakpoints.down("xs") ? 160 : 200;
    return {
        minWidth: isIcon ? 60 : minWidth,
        py: 0,
        px: 1,
        minHeight: 34,
    };
};

export const tabContent = (theme: Theme, ownerState: any) => {
    const { palette } = theme;
    const { active, item } = ownerState;

    const { primary, white } = palette;
    return {
        color: active === item.value ? white.main : primary.dark,
        marginRight: "0 !important",
    };
};
