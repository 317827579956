const form = {
    formId: "new-business-form",
    formField: {
        business: {
            name: {
                name: "name",
                type: "text",
                errorMsg: "Business name is required",
            },
            address: {
                name: "address",
                type: "text",
                errorMsg: "Address is required",
            },
            contact: {
                name: "contact",
                type: "text",
                errorMsg: "Emergency contact is required",
            },
            phone: {
                number: {
                    name: "phone.number",
                    type: "tel",
                    errorMsg: "Mobile is required",
                    invalidMsg: "Must be 10 digits",
                },
                countryCode: {
                    name: "phone.countryCode",
                    type: "text",
                },
            },
            description: {
                name: "description",
                type: "text",
            },
            relManagerEmail: {
                name: "relManagerEmail",
                type: "text",
                invalidMsg: "Invalid email format",
            },
            relAssistantEmail: {
                name: "relAssistantEmail",
                type: "text",
                invalidMsg: "Invalid email format",
            },
            dashboardUrl: {
                name: "dashboardUrl",
                type: "text",
            },
        },
        center: {
            name: {
                name: "name",
                type: "text",
                errorMsg: "Center name is required",
            },
            address: {
                name: "address",
                type: "text",
                errorMsg: "Address is required",
            },
            contact: {
                name: "contact",
                type: "text",
                errorMsg: "Emergency contact is required",
            },
            phone: {
                number: {
                    name: "phone.number",
                    type: "tel",
                    errorMsg: "Mobile is required",
                    invalidMsg: "Must be 10 digits",
                },
                countryCode: {
                    name: "phone.countryCode",
                    type: "text",
                },
            },
            description: {
                name: "description",
                type: "text",
            },
            isCentralized: {
                name: "isCentralized",
                type: "radio",
            },
        },
        unit: {
            centerName: {
                name: "centerName",
                type: "text",
            },
            floor: {
                name: "floor",
                type: "text",
                errorMsg: "Floor is required",
            },
            unitNumber: {
                name: "unitNumber",
                type: "text",
                errorMsg: "Unit No. is required",
            },
            numberOfResidents: {
                name: "numberOfResidents",
                type: "text",
            },
            address: {
                name: "address",
                type: "text",
                errorMsg: "Address is required",
            },
            description: {
                name: "description",
                type: "text",
            },
            roomDevices: {
                room: {
                    name: "roomDevices.room",
                    type: "text",
                },
                installationPhotoUrls: {
                    name: "roomDevices.installationPhotoUrls",
                    type: "file",
                },
                uploadableImages: {
                    name: "roomDevices.uploadableImages",
                    type: "file",
                },
                devices: {
                    room: {
                        name: "roomDevices.devices.room",
                        type: "text",
                    },
                    status: {
                        name: "roomDevices.devices.status",
                        type: "text",
                    },
                    model: {
                        name: "roomDevices.devices.model",
                        type: "text",
                    },
                    serialNumber: {
                        name: "roomDevices.devices.serialNumber",
                        type: "text",
                    },
                },
            },
            anderDevices: {
                room: {
                    name: "anderDevices.room",
                    type: "text",
                },
                status: {
                    name: "anderDevices.status",
                    type: "text",
                },
                model: {
                    name: "anderDevices.model",
                    type: "text",
                },
                serialNumber: {
                    name: "anderDevices.serialNumber",
                    errorMsg: "Serial number is required",
                    type: "text",
                },
            },
            roomType:{
                name: "roomType",
                type: "text",
            }
        },
        resident: {
            avatarUrl: {
                name: "avatarUrl",
                type: "file",
            },
            name: {
                name: "name",
                type: "text",
                errorMsg: "Resident name is required",
            },
            number: {
                name: "number",
                type: "text",
            },
            gender: {
                name: "gender",
                type: "checkbox",
            },
            dateOfBirth: {
                name: "dateOfBirth",
                type: "date",
                errorMsg: "Date of Birth is required",
                invalidMsg: "Date cannot be in the future",
            },
            address: {
                name: "address",
                type: "text",
                errorMsg: "Address is required",
            },
            phone: {
                number: {
                    name: "phone.number",
                    type: "tel",
                    errorMsg: "Mobile is required",
                    invalidMsg: "Must be 10 digits",
                },
                countryCode: {
                    name: "phone.countryCode",
                    type: "text",
                },
            },
            email: {
                name: "email",
                type: "text",
            },
            emergency: {
                name: {
                    name: "emergency.name",
                    type: "text",
                    errorMsg: "Name is required",
                },
                phone: {
                    number: {
                        name: "emergency.phone.number",
                        type: "tel",
                        errorMsg: "Mobile is required",
                        invalidMsg: "Must be 10 digits",
                    },
                    countryCode: {
                        name: "emergency.phone.countryCode",
                        type: "text",
                    },
                },
                email: {
                    name: "emergency.email",
                    type: "text",
                },
                relation: {
                    name: "emergency.relation",
                    type: "text",
                },
                notification: {
                    name: "emergency.notification",
                    type: "checkbox",
                },
            },
            center: {
                name: {
                    name: "center.name",
                    type: "text",
                },
            },
            unit: {
                name: "unit",
                type: "text",
                errorMsg: "Unit is required",
            },
            floor: {
                name: "floor",
                type: "text",
                errorMsg: "Floor is required",
            },
            preferredLanguage: {
                name: "preferredLanguage",
                type: "text",
            },
            diabetes: {
                name: "diabetes",
                type: "text",
            },
        },
        unitAlarm: {
            weakVitalSign: {
                hasAlarmNotice: {
                    name: "weakVitalSign.hasAlarmNotice",
                    type: "checkbox",
                },
                hasLine: {
                    name: "weakVitalSign.hasLine",
                    type: "checkbox",
                },
                period: {
                    name: "weakVitalSign.period",
                    type: "number",
                },
            },
            fallAlarm: {
                hasAlarmNotice: {
                    name: "fallAlarm.hasAlarmNotice",
                    type: "checkbox",
                },
                hasLine: {
                    name: "fallAlarm.hasLine",
                    type: "checkbox",
                },
                period: {
                    name: "fallAlarm.period",
                    type: "number",
                },
            },
            notInRoom: {
                hasAlarmNotice: {
                    name: "notInRoom.hasAlarmNotice",
                    type: "checkbox",
                },
                hasLine: {
                    name: "notInRoom.hasLine",
                    type: "checkbox",
                },
                period: {
                    name: "notInRoom.period",
                    type: "number",
                },
                timeRange: {
                    name: "notInRoom.timeRange",
                    type: "string",
                },
                st: {
                    name: "notInRoom.st",
                    type: "text",
                },
                et: {
                    name: "notInRoom.et",
                    type: "text",
                },
                sm: {
                    name: "notInRoom.sm",
                    type: "text",
                },
                em: {
                    name: "notInRoom.em",
                    type: "text",
                },
            },
            stayInRoom: {
                alarm: {
                    name: "stayInRoom.alarm",
                    type: "checkbox",
                },
                instantMessage: {
                    name: "stayInRoom.instantMessage",
                    type: "checkbox",
                },
                rooms: {
                    room: {
                        name: "stayInRoom.rooms.room",
                        type: "text",
                    },
                    duration: {
                        name: "stayInRoom.rooms.duration",
                        type: "number",
                    },
                },
            },
        },
        residentialAlarm: {
            turnSetting: {
                hasAlarmNotice: {
                    name: "turnSetting.hasAlarmNotice",
                    type: "checkbox",
                },
                hasLine: {
                    name: "turnSetting.hasLine",
                    type: "checkbox",
                },
                turnSetting: {
                    name: "turnSetting.period",
                    type: "number",
                },
            },
            heartRate: {
                hasAlarmNotice: {
                    name: "heartRate.hasAlarmNotice",
                    type: "checkbox",
                },
                hasLine: {
                    name: "heartRate.hasLine",
                    type: "checkbox",
                },
                period: {
                    name: "heartRate.period",
                    type: "number",
                },
                min: {
                    name: "heartRate.min",
                    type: "number",
                },
                max: {
                    name: "heartRate.max",
                    type: "number",
                },
            },
            respirationRate: {
                hasAlarmNotice: {
                    name: "respirationRate.hasAlarmNotice",
                    type: "checkbox",
                },
                hasLine: {
                    name: "respirationRate.hasLine",
                    type: "checkbox",
                },
                period: {
                    name: "respirationRate.period",
                    type: "number",
                },
                max: {
                    name: "respirationRate.max",
                    type: "string",
                },
            },
            temperature: {
                hasAlarmNotice: {
                    name: "temperature.hasAlarmNotice",
                    type: "checkbox",
                },
                hasLine: {
                    name: "temperature.hasLine",
                    type: "checkbox",
                },
                period: {
                    name: "temperature.period",
                    type: "number",
                },
                min: {
                    name: "temperature.min",
                    type: "number",
                },
                max: {
                    name: "temperature.max",
                    type: "number",
                },
            },
            leaving: {
                hasAlarmNotice: {
                    name: "leaving.hasAlarmNotice",
                    type: "checkbox",
                },
                hasLine: {
                    name: "leaving.hasLine",
                    type: "checkbox",
                },
                period: {
                    name: "leaving.period",
                    type: "number",
                },
                timeRange: {
                    name: "leaving.timeRange",
                    type: "string",
                },
                st: {
                    name: "leaving.st",
                    type: "text",
                },
                et: {
                    name: "leaving.et",
                    type: "text",
                },
                sm: {
                    name: "leaving.sm",
                    type: "text",
                },
                em: {
                    name: "leaving.em",
                    type: "text",
                },
            },
            lineSetting: {
                name: "lineSetting",
                type: "number",
            },
        },
        // residentialAlarm: {
        //     phyAlarm: {
        //         interval: {
        //             name: "phyAlarm.interval",
        //             type: "number",
        //         },
        //         rrRange: {
        //             name: "phyAlarm.hrRange",
        //             type: "number",
        //         },
        //         hrRange: {
        //             name: "phyAlarm.brRange",
        //             type: "number",
        //         },
        //         tempRange: {
        //             name: "phyAlarm.tempRange",
        //             type: "number",
        //         },
        //     },
        //     bedAlarm: {
        //         interval: {
        //             name: "bedAlarm.interval",
        //             type: "number",
        //         },
        //         leaveTime: {
        //             name: "bedAlarm.leaveTime",
        //             type: "number",
        //         },
        //         turnOver: {
        //             name: "bedAlarm.turnOver",
        //             type: "number",
        //         },
        //     },
        // },
    },
};

export default form;
