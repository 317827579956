import Grid from "@mui/material/Grid/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UnitService from "services/unit.service";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon/Icon";
import MDInput from "components/MDInput";
import MDCircularProgress from "components/MDCircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AddButton from "components/MDButton/addButton";
import MDModal from "components/MDModal";
import { ToggleProp } from "types/state";
import { NewMDModal } from "components/MDModal";
import CenterService from "services/center.service";
import MDNewFilter from "components/MDNewFilter";
interface Props extends ToggleProp {
    businessId: string;
    open: boolean;
}
const FloorSetting = (props: Props) => {
    const { setToggleModal, open, businessId } = props;
    const { t: translate } = useTranslation(["common"]);
    const [roomData, setRoomData] = useState<any[]>([]);
    const [selectedRoom, setSelectedRoom] = useState<any>();
    const [setting, setSetting] = useState(null);
    const [_setting, _setSetting] = useState<any[]>([]);
    const [floor, setFloor] = useState<string>("");
    const [grid, setGrid] = useState<{ column: string; row: string }>({ column: "1", row: "1" });
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteable, setDeleteable] = useState(true);
    const [newRoom, setNewRoom] = useState(false);
    const [modal, setModal] = useState(false);
    const [centerList, setCenterList] = useState<any[]>([]);
    const [activeCenter, setActiveCenter] = useState<string>(null);
    const getWistronRoomList = async () => {
        setLoading(true);
        setDeleteable(false);
        setGrid({ column: "0", row: "0" });
        setSetting(null);
        setEdit(false);
        _setSetting([]);
        setSelectedRoom(0);
        setNewRoom(false);
        const res = await UnitService.getWistronRoomList(activeCenter);
        if (res && res.data.length > 0) {
            console.log(res.data);
            setRoomData(res.data);
            setSelectedRoom(res.data.length - 1);
            setLoading(false);
        } else {
            setRoomData([]);
            setLoading(false);
        }
    };

    const getCenterList = async () => {
        const res = await CenterService.getAllCenters(businessId);
        if (res) {
            setActiveCenter(res.data[0]?._id);
            setCenterList(res.data);
        }
    };
    const addWistronRoom = async (body: any) => {
        const res = await UnitService.addWistronRoom(body);
        if (res) {
            getWistronRoomList();
        }
    };
    const updateWistronRoom = async (body: any) => {
        const res = await UnitService.updateWistronRoom(body);
        if (res) {
            getWistronRoomList();
        }
    };
    const deleteWistronRoom = async (floor: any) => {
        const res = await UnitService.deleteWistronRoom(floor);
        setModal(false);
        if (res) {
            getWistronRoomList();
        }
    };
    const getWistronRoom = async (id: any, floor: any) => {
        const res = await UnitService.getWistronRoom(id, floor);
        if (res) {
            return res.data;
        }
    };
    const handleSelectRoom = (room: any) => {
        if (loading) return;
        setSelectedRoom(room);
    };

    const handleInputChange = (index: any, event: any) => {
        const updatedSetting = [..._setting];
        console.log(event.target.value);
        updatedSetting[index].room = event.target.value;
        _setSetting(updatedSetting);
    };

    const handleEdit = () => {
        if (edit === false) {
            setEdit(!edit);
        } else {
            setEdit(!edit);
            let _settingCopy = JSON.parse(JSON.stringify(_setting));

            _settingCopy.forEach((room: any, index: any) => {
                delete _settingCopy[index].editable;
            });

            // console.log(body,roomData[selectedRoom]);

            // addWistronRoom(body);
            if (roomData[selectedRoom].setting) {
                let body = structuredClone(roomData[selectedRoom]);
                body.setting = _settingCopy;
                body.grid = grid;
                body.floor = floor;
                updateWistronRoom(body);
                console.log(roomData, body);
            } else {
                let body = {
                    id: selectedRoom,
                    center: activeCenter,
                    grid: grid,
                    setting: _settingCopy,
                    floor: floor,
                };
                console.log(roomData, body);
                addWistronRoom(body);
            }
        }
    };

    const handleCancelOrDelete = () => {
        if (edit) {
            if (newRoom) {
                console.log("delete new room");
                setNewRoom(false);
                const updatedRoomData = roomData.slice(0, roomData.length - 1);
                setRoomData(updatedRoomData);
                setSelectedRoom(updatedRoomData.length - 1);
            } else {
                console.log("cancel edit");
                setEdit(false);
                setSetting(JSON.parse(roomData[selectedRoom].setting));
                setGrid(JSON.parse(roomData[selectedRoom].grid));
            }
        } else {
            setModal(true);
        }
    };

    const handleAddRoom = () => {
        console.log("add room");
        if (newRoom) return;
        setNewRoom(true);
        setRoomData([...roomData, { floor: "New", grid: JSON.stringify({ column: "1", row: "1" }), setting: "" }]);
        setSelectedRoom(roomData.length);
        setDeleteable(false);
        setEdit(true);
    };

    const renderTable = () => {
        return (
            <>
                <MDBox
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        maxWidth: "1000px",
                        width: "100%",
                    }}>
                    <MDBox sx={{ display: "flex", fontSize: "20px", marginBottom: "8px", flexDirection: "column" }}>
                        <MDTypography sx={{ fontSize: "20px" }}>{translate(`setting.room.floor.plan`)}</MDTypography>
                        {edit && !deleteable ? (
                            <MDInput
                                value={floor}
                                variant="outlined"
                                onChange={(event: any) => {
                                    const newValue = event.target.value;
                                    setFloor(newValue);
                                    if (newValue.length <= 3) {
                                        
                                        // const updatedRoomData = [...roomData];
                                        // updatedRoomData[selectedRoom].floor = newValue;
                                        // setRoomData(updatedRoomData);
                                    }
                                }}
                                sx={{
                                    width: "250px",
                                    // textAlignLast: "center",
                                    marginTop: "10px",
                                    "& .MuiInputBase-input": { padding: "5px" },
                                }}
                            />
                        ) : (
                            <MDTypography
                                sx={{
                                    marginTop: "15px",
                                    marginRight: "35px",
                                }}>{`${roomData[selectedRoom].floor}`}</MDTypography>
                        )}
                    </MDBox>
                </MDBox>
                <MDBox display="flex">
                    <MDBox
                        sx={{
                            fontSize: "20px",
                            marginRight: "25px",
                            color: "#000000a6",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                        {!edit || deleteable ? (
                            `${grid.column} ${translate(`setting.room.floor.column`)}`
                        ) : (
                            <>
                                <MDBox>{translate(`setting.room.floor.column`)}</MDBox>
                                <Select
                                    inputProps={{ "aria-label": "Without label" }}
                                    value={grid.column}
                                    onChange={(event: SelectChangeEvent) =>
                                        setGrid({ ...grid, column: event.target.value })
                                    }
                                    sx={{
                                        width: "100px",
                                        textAlignLast: "center",
                                        marginRight: "6px",
                                        padding: "5px 0",
                                        marginTop: "10px",
                                    }}>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </Select>
                            </>
                        )}
                    </MDBox>
                    <MDBox sx={{ fontSize: "20px", color: "#000000a6", display: "flex", flexDirection: "column" }}>
                        {!edit || deleteable ? (
                            `${grid.row} ${translate(`setting.room.floor.row`)}`
                        ) : (
                            <>
                                <MDBox>{translate(`setting.room.floor.row`)}</MDBox>
                                <Select
                                    inputProps={{ "aria-label": "Without label" }}
                                    value={grid.row}
                                    onChange={(event: SelectChangeEvent) =>
                                        setGrid({ ...grid, row: event.target.value })
                                    }
                                    sx={{
                                        width: "100px",
                                        textAlignLast: "center",
                                        marginRight: "6px",
                                        padding: "5px 0",
                                        marginTop: "10px",
                                    }}>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </Select>
                            </>
                        )}
                    </MDBox>
                </MDBox>
                <MDBox sx={{ paddingTop: "15px" }}>
                    <Grid container sx={{ maxWidth: "1000px" }} rowGap={5}>
                        {loading ? (
                            <MDCircularProgress size={20} />
                        ) : (
                            _setting.map((room: any, index: any) => (
                                <Grid
                                    item
                                    sx={{
                                        display: { xs: "flex" },
                                        justifyContent: { xs: "center" },
                                        alignItems: { xs: "center" },
                                    }}
                                    key={index}
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={2.4}>
                                    <MDBox
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            // height: "100%",
                                            height: "125px",
                                            width: "125px",
                                            border: "1px solid #cccccc",
                                            margin: "5px",
                                            borderRadius: "15px",
                                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                                        }}>
                                        {!edit ? (
                                            <MDTypography sx={{ fontSize: "14px" }}>{room.room}</MDTypography>
                                        ) : (
                                            <MDInput
                                                value={room.room}
                                                variant="outlined"
                                                disabled={!room.editable}
                                                onChange={(event: any) => handleInputChange(index, event)}
                                                sx={{ width: "100px", textAlignLast: "center" }}
                                            />
                                        )}
                                    </MDBox>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </MDBox>
                <MDBox sx={{ marginTop: "auto", paddingTop: "25px" }}>
                    <MDBox sx={{ display: "flex", fontSize: "20px", justifyContent: "right" }}>
                        <MDButton
                            type="button"
                            variant="outlined"
                            circular={false}
                            color="error"
                            disabled={edit ? false : loading || deleteable}
                            sx={{ minWidth: "100px", width: "100px" }}
                            onClick={() => handleCancelOrDelete()}>
                            {edit ? translate(`general.button.cancel`) : translate(`general.button.delete`)}
                        </MDButton>
                        <MDButton
                            sx={{ minWidth: "100px", width: "100px", marginLeft: "20px" }}
                            circular={false}
                            disabled={loading}
                            onClick={() => handleEdit()}>
                            <Icon fontSize="medium" sx={{ cursor: "pointer", marginRight: "5px" }}>
                                create
                            </Icon>
                            {edit ? translate(`general.button.update`) : translate(`general.button.edit`)}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </>
        );
    };

    const deleteModal = () => {
        return (
            <MDBox
                sx={{
                    display: "flex",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,.5)",
                    zIndex: "999",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <MDBox
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        padding: "20px",
                        borderRadius: "10px",
                    }}>
                    <MDTypography sx={{ fontSize: "20px", marginBottom: "20px" }}>
                        {translate(`general.popup.deleteFloor`)}
                    </MDTypography>
                    <MDBox sx={{ display: "flex", justifyContent: "right", width: "100%" }}>
                        <MDButton
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                deleteWistronRoom(roomData[selectedRoom]._id);
                            }}>
                            {translate(`general.button.delete`)}
                        </MDButton>
                        <MDButton
                            variant="outlined"
                            color="primary"
                            sx={{ marginLeft: "20px" }}
                            onClick={() => {
                                setDeleteable(false);
                                setModal(false);
                            }}>
                            {translate(`general.button.cancel`)}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDBox>
        );
    };
    const handleCenterChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setActiveCenter(e.target.value as string);
    };

    useEffect(() => {
        getCenterList();
    }, []);

    useEffect(() => {
        console.log("activeCenter", activeCenter);
        if (activeCenter) {
            getWistronRoomList();
        }
    }, [activeCenter]);

    useEffect(() => {
        if (!newRoom) {
            setEdit(false);
        }

        if (roomData[selectedRoom]?.setting) {
            setFloor(roomData[selectedRoom]?.floor);
            setSetting(JSON.parse(roomData[selectedRoom]?.setting));
            setGrid(JSON.parse(roomData[selectedRoom]?.grid));
        }
        if (newRoom) {
            setFloor("New");
            setSetting([]);
            setGrid({ column: "1", row: "1" });
        }
    }, [selectedRoom, roomData]);

    useEffect(() => {
        const fetchRoomData = async () => {
            if (setting && !edit) {
                const settingCopy = setting.map((room: any) => ({ ...room, editable: true })); // Initialize `editable` to true
                setDeleteable(false);
                for (let index = 0; index < settingCopy.length; index++) {
                    const room = settingCopy[index];
                    try {
                        setLoading(true);
                        const roomInfo = await getWistronRoom(room.room, roomData[selectedRoom].floor);

                        if (roomInfo.length > 0 && roomInfo[0].roomName == room.room) {
                            settingCopy[index].editable = false;
                            setDeleteable(true);
                        }
                    } catch (error) {
                        console.error(`Error fetching data for room ${room.room}:`, error);
                    }
                }
                setLoading(false);
                _setSetting(settingCopy);
            }
        };

        fetchRoomData();
    }, [setting]);

    useEffect(() => {
        if (edit) {
            const newGrid = [];
            for (let i = 0; i < parseInt(grid.column) * parseInt(grid.row); i++) {
                newGrid.push({ room: "", order: i, editable: true });
            }
            _setSetting(newGrid);
        }
    }, [grid]);

    return (
        <NewMDModal type="form" setToggleModal={setToggleModal} customWidth="1000px" open={open}>
            {modal && deleteModal()}
            {/* <MDModal type="form" setToggleModal={setToggleModal}> */}
            <MDBox sx={{ background: "white", borderRadius: "25px" }}>
                <MDBox sx={{ paddingLeft: "25px", paddingTop: "25px" }}>
                    <MDTypography sx={{ fontSize: "1.375rem", fontWeight: "900" }}>
                        {translate(`general.button.addFloor`)}
                    </MDTypography>
                </MDBox>
                <MDBox sx={{ padding: "16px 10px" }}>
                    <MDTypography
                        sx={{ fontSize: "18px", fontWeight: "900", paddingLeft: "16px", paddingBottom: "8px" }}>
                        {translate(`setting.center.name`)}
                    </MDTypography>
                    <MDNewFilter
                        value={activeCenter}
                        data={centerList}
                        handleChange={handleCenterChange}
                        mb={0}
                        type={1}
                    />
                </MDBox>
                <MDBox sx={{ display: "flex", clear: "both", padding: "20px 0 ", width: "100%" }} />
                <Grid
                    container
                    sx={{
                        width: "100%",
                        minHeight: "500px",
                        paddingBottom: { sm: "25px" },
                        paddingRight: { sm: "25px" },
                        padding: { xs: "10px" },
                    }}>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        sx={{
                            borderRight: {sm:"1px solid #cccccc"},
                            borderBottom: {xs:"1px solid #cccccc"},
                            textAlign: "center",
                            // overflowY: "scroll",
                        }}>
                        <MDBox>
                            <MDBox sx={{ marginBottom: "35px" }}>
                                <AddButton
                                    content={translate(`general.button.addFloor`)}
                                    handleToggleModal={() => {
                                        setEdit(true);
                                        handleAddRoom();
                                    }}
                                    isDisabled={loading || newRoom}
                                />
                            </MDBox>

                            {roomData.map((room, index) => {
                                const grid = JSON.parse(room.grid);
                                return (
                                    <MDBox
                                        key={index}
                                        sx={{ padding: "8px 25px", cursor: loading ? "not-allowed" : "pointer" }}
                                        onClick={() => handleSelectRoom(index)}>
                                        <MDBox
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                // width: "200px",
                                                width: "100%",

                                                height: "75px",
                                                borderRadius: "12px",
                                                backgroundColor: selectedRoom === index ? "#E9DEEE" : "#FFFFFF",
                                                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                                            }}>
                                            <MDBox
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "baseline",
                                                    width: "100%",
                                                    justifyContent: "space-around",
                                                }}>
                                                <MDBox>
                                                    <MDTypography sx={{ fontSize: "32px", fontWeight: "700" }}>
                                                        {room.floor}
                                                    </MDTypography>
                                                </MDBox>
                                                <MDBox>
                                                    <MDTypography
                                                        sx={{
                                                            fontSize: "12px",
                                                            color: "#000000a6",
                                                            paddingLeft: "4px",
                                                        }}>{`${grid.column * grid.row} ${translate(
                                                        `setting.room.floor.room`,
                                                    )}`}</MDTypography>
                                                </MDBox>
                                            </MDBox>
                                            {/* {selectedRoom === index && <KeyboardArrowRightIcon />} */}
                                        </MDBox>
                                    </MDBox>
                                );
                            })}
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        <MDBox
                            sx={{
                                boxSizing: "border-box",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: "25px",
                                paddingTop:{xs:"25px"},
                            }}>
                            {roomData.length > 0 && setting && renderTable()}
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </NewMDModal>
    );
};

export default FloorSetting;
