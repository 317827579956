import MDBox from "components/MDBox";
import MDTab from "components/MDTab";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReportTable from "./ReportTable";
import ResidentService from "services/resident.service";
import ReportXTable from "./ReportXTable";
import MDCircularProgress from "components/MDCircularProgress";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { use } from "i18next";

interface Props {}
export const TodayReport = (props: Props) => {
    const { t: translate } = useTranslation(["common"]);
    const [tab, setTab] = useState<string>("anderReport");
    const [data, setData] = useState<any[]>([]);
    const [dataX, setDataX] = useState<any[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [room, setRoom] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const fetchRoom = async () => {
        try {
            const response = await ResidentService.getAllResidentWithWistronId();
            setRoom(response.data);
        } catch (error) {
            console.error("fetchRoom -> error", error);
        }
    };

    const fetchTodayReport = async () => {
        try {
            const response = await ResidentService.getAllTodayAnderReport(page);
            setTotal(response.count);
            setData(response.data);
        } catch (error) {
            console.error("fetchTodayReport -> error", error);
        }
    };

    const fetchTodayReportX = async () => {
        try {
            const response = await ResidentService.getAllTodayAnderXReport(page);
            setTotal(response.count);
            setDataX(response.data);
        } catch (error) {
            console.error("fetchTodayReportX -> error", error);
        }
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    };
    useEffect(() => {
        if (tab === "anderReport") {
            fetchTodayReport();
        } else {
            fetchTodayReportX();
        }
    }, [page]);

    useEffect(() => {
        setIsFetching(true);

        Promise.all([fetchRoom(), fetchTodayReport(), fetchTodayReportX()]).finally(() => {
            setIsFetching(false);
        });
    }, []);
    useEffect(() => {
        setPage(1);
        setTotal(0);
        if (tab === "anderReport") {
            fetchTodayReport();
        } else {
            fetchTodayReportX();
        }
    }, [tab]);
    return (
        <MDBox p={2}>
            <MDBox sx={{ float: {md:"right"} }}>
                <MDTab
                    list={[
                        { key: translate("report.anderReport"), value: "anderReport" },
                        { key: translate("report.anderXReport"), value: "anderXReport" },
                    ]}
                    active={tab}
                    scrollable={false}
                    setActive={setTab}
                />
            </MDBox>
            <MDBox>
                {isFetching ? (
                    <MDCircularProgress size={20} />
                ) : tab === "anderReport" ? (
                    <ReportTable list={data} page={page} count={total} room={room} setPage={handlePageChange} />
                ) : (
                    <ReportXTable list={dataX} page={page} count={total} room={room} setPage={handlePageChange} />
                )}
            </MDBox>
        </MDBox>
    );
};

export default TodayReport;
