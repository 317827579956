/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { isNotNil } from "helpers/utils";

const configs = (labels: any, datasets: any,pluginsoptions?:any) => {
    return {
        data: {
            labels,
            datasets: [...datasets],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                ...pluginsoptions,
                legend: {
                    display: false,
                },
            },
            interaction: {
                intersect: false,
                mode: "index",
            },
            scales: {
                y: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                        color: "#c1c4ce5c",
                    },
                    ticks: {
                        display: true,
                        padding: 10,
                        color: "#9ca2b7",
                        font: {
                            size: 14,
                            weight: 300,
                            family: "Roboto",
                            style: "normal",
                            lineHeight: 2,
                        },
                    },
                    // Max and min value will be max + 5 and min -5 of the existing data
                    // max: (scale: any) => {
                    //     const res = scale.chart.data.datasets.reduce((acc: number, curr: any) => {
                    //         const max = isNotNil(curr.data) ? Math.max(...curr.data) : NaN;
                    //         acc = !Number.isNaN(max) && max > acc ? max : acc;
                    //         return acc;
                    //     }, Number.MIN_SAFE_INTEGER);
                    //     return res + 5;
                    // },
                    // min: (scale: any) => {
                    //     const res = scale.chart.data.datasets.reduce((acc: number, curr: any) => {
                    //         const min = isNotNil(curr.data) ? Math.min(...curr.data) : NaN;
                    //         acc = !Number.isNaN(min) && min < acc ? min : acc;
                    //         return acc;
                    //     }, Number.MAX_SAFE_INTEGER);
                    //     if(res == 0)
                    //         return res
                    //     return res - 5;
                    // },
                },
                x: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: true,
                        borderDash: [5, 5],
                        color: "#c1c4ce5c",
                    },
                    ticks: {
                        display: true,
                        color: "#9ca2b7",
                        padding: 10,
                        font: {
                            size: 14,
                            weight: 300,
                            family: "Roboto",
                            style: "normal",
                            lineHeight: 2,
                        },
                    },
                },
            },
        },
    };
};

export default configs;
