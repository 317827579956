import MDBox from "components/MDBox";
import { useTranslation } from "react-i18next";
import PageLayout from "examples/LayoutContainers/PageLayout";
import brand from "assets/images/logos/Wecare-logo.svg";
import Markdown from "markdown-to-jsx";
import { Fragment, useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { useSearchParams } from "react-router-dom";
import HealthPre from "services/healthprescription.service";
import { HealthPreScription } from "types/healthprescription-schema";
import { isNotNil } from "helpers/utils";
import MDCircularProgress from "components/MDCircularProgress";

const StyledTitle = (props: any) => {
    const { children } = props;
    return (
        <MDBox
            sx={{
                position: "relative",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    bottom: -13,
                    left: 0,
                    width: "40px",
                    height: "4px",
                    background: "#7D0FB1",
                },
                marginTop: "20px",
                marginBottom: "20px",
            }}>
            <MDTypography variant="h2" fontWeight="regular" sx={{ color: "#7D0FB1" }}>
                {children}
            </MDTypography>
        </MDBox>
    );
};

const StyledList = (props: any) => {
    const { children } = props;
    return <MDBox sx={{ paddingTop: "20px",paddingLeft:"30px",ul:{listStylePosition: 'outside'},li:{"&+li":{paddingTop:"10px"}} }}><ul>{children}</ul></MDBox>;
};

const PrescriptionType = (props: any) => {
    const { children } = props;
    const { t: translate } = useTranslation(["common"]);
    return (
        <MDBox
            sx={{
                padding: "5px 15px",
                background: "#7D0FB1",
                width: "fit-content",
                color: "white !important",
                borderRadius: "5px",
                marginBottom: "20px",
            }}>
            {translate(`info.prescription.${children}`)}
        </MDBox>
    );
};

export const HealthPrescription: React.FC<any> = ({ url }) => {
    const { t: translate } = useTranslation(["common"]);
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<HealthPreScription>();

    const fetchHealthEdu = async () => {
        try {
            const res = await HealthPre.getHealthPrescription(searchParams.get("id"));


            if (res.data) {
                setData(res.data);
            }
        } catch (error) {}
    };

    useEffect(() => {
        if (isNotNil(searchParams.get("id"))) {
            fetchHealthEdu();
        }
    }, []);

    return (
        <MDBox sx={{ background: "#F9F9FF", padding: "15px 0 ", minHeight: "100vh", lineHeight: "35px" }}>
            <MDBox
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    mx: "auto",
                    padding: { xs: "8px" },
                }}>
                <a href="https://www.realeasyliving.com/" target="_blank">
                    <MDBox sx={{ display: "flex", alignItems: "center", paddingBottom: "24px" }}>
                        <MDBox component="img" src={brand} alt="Brand" />
                        {/* <MDBox sx={{ fontSize: "34px", fontWeight: "100", color: "#7D0FB1" }}>REL Living</MDBox> */}
                    </MDBox>
                </a>

                <MDBox
                    sx={{
                        maxWidth: "1440px",
                        // minHeight: "770px",
                        padding: { xs: "32px 16px", md: "32px" },
                        width: "100%",
                        height: "100%",
                        background: "white",
                        borderRadius: "20px",
                    }}>
                    {data!=null ? (
                        <>
                            {/* <PageTitle title={data.attributes.health_care_type.data.attributes.Name} /> */}
                            <MDBox sx={{ paddingTop: "32px", display: "flex", flexDirection: "column" }}>
                                {/* <StyledTitle
                                    title={`${data.attributes.health_care_type.data.attributes.Name} - ${data.attributes.risk_type.data.attributes.Name}`}
                                /> */}
                                <MDBox sx={{ paddingLeft: "40px", fontSize: "20px", lineHeight: "180%" }}>
                                    <Markdown
                                        options={{
                                            wrapper: Fragment,
                                            forceWrapper: false,
                                            overrides: {
                                                h2: {
                                                    component: StyledTitle,
                                                },
                                            },
                                        }}>
                                        {data.attributes.content}
                                    </Markdown>
                                </MDBox>
                            </MDBox>
                        </>
                    ) : (
                        <MDCircularProgress />
                    )}
                </MDBox>
                <MDBox
                    sx={{
                        maxWidth: "1440px",
                        // minHeight: "770px",
                        padding: { xs: "32px 16px", md: "32px" },
                        width: "100%",
                        height: "100%",
                        marginTop: "20px",
                        background: "white",
                        borderRadius: "20px",
                    }}>
                    {data!=null ? (
                        <>
                            <MDBox sx={{ paddingTop: "32px", display: "flex", flexDirection: "column" }}>
                                <MDBox sx={{ paddingLeft: "40px", fontSize: "20px", lineHeight: "180%" }}>
                                    <PrescriptionType>
                                        {data.attributes.health_prescription_type.data.attributes.Name}
                                    </PrescriptionType>
                                    <Markdown
                                        options={{
                                            wrapper: Fragment,
                                            forceWrapper: false,
                                            overrides: {
                                                h2: {
                                                    component: StyledTitle,
                                                },
                                                ul: {
                                                    component: StyledList,
                                                },
                                            },
                                        }}>
                                        {data.attributes.type_content}
                                    </Markdown>
                                </MDBox>
                            </MDBox>
                        </>
                    ) : (
                        <MDCircularProgress />
                    )}
                </MDBox>
                {(data && data.attributes.remark) && (
                    <MDBox
                        sx={{
                            maxWidth: "1440px",
                            // minHeight: "770px",
                            padding: { xs: "32px 16px", md: "32px" },
                            width: "100%",
                            height: "100%",
                            marginTop: "20px",
                            background: "#EBEBF9",
                            borderRadius: "20px",
                        }}>
                        <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                            <MDBox sx={{ paddingLeft: "40px", fontSize: "20px", lineHeight: "180%" }}>
                                <Markdown
                                    options={{
                                        wrapper: Fragment,
                                        forceWrapper: false,
                                        overrides: {
                                            h2: {
                                                component: StyledTitle,
                                            },
                                        },
                                    }}>
                                    {data.attributes.remark}
                                </Markdown>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )}
            </MDBox>
        </MDBox>
    );
};

export default HealthPrescription;
