import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

interface Props {
    contact: string;
    phone: { countryCode: string; number: string };
    address?: string;
}

export const ContactDetails: React.FC<Props> = ({ contact, phone, address }) => {
    return (
        <Grid item xs={12} md={4.5}>
            <MDBox display="flex" justifyContent="space-between">
                <MDTypography>{contact}</MDTypography>
                <MDTypography>
                    {phone.countryCode} {phone.number}
                </MDTypography>
            </MDBox>
            {address && (
                <MDBox sx={{paddingTop:{xs:2,md:0}}}>
                    <MDTypography>{address}</MDTypography>
                </MDBox>
            )}
        </Grid>
    );
};

export default ContactDetails;
