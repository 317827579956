import { Response, default as http } from "http-common";
import { Unit } from "types/setting-schema";

class UnitService {
    async getAllUnitsByCenter(centerId: string): Promise<Response<Unit[]>> {
        const response = await http.get<Response<Unit[]>>(`/api/v1/centers/${centerId}/units`);
        return response.data;
    }

    async getAllUnitsByBusiness(businessId: string): Promise<Response<Unit[]>> {
        const response = await http.get<Response<Unit[]>>(`/api/v1/businesses/${businessId}/units`);
        return response.data;
    }

    async getSingleUnit(unitId: string): Promise<Response<Unit>> {
        const response = await http.get<Response<Unit>>(`/api/v1/units/${unitId}`);
        return response.data;
    }

    async updateSingleUnit(unitId: string, unitInfo: Unit): Promise<Response<Unit>> {
        const response = await http.put<Response<Unit>>(`/api/v1/units/${unitId}`, unitInfo);
        return response.data;
    }

    async createUnit(centersId: string, unitInfo: Unit): Promise<Response<Unit>> {
        const response = await http.post<Response<Unit>>(`/api/v1/centers/${centersId}/units`, unitInfo);
        return response.data;
    }

    async deleteSingleUnit(unitId: string): Promise<Response<Unit>> {
        const response = await http.delete<Response<Unit>>(`/api/v1/units/${unitId}`);
        return response.data;
    }

    
    async getWistronRoomList(center:string): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/units/wistronFloorInfos?center=${center}`);
        return response.data;
    }

    async addWistronRoom(body:any): Promise<Response<any>> {
        const response = await http.post<Response<any>>(`/api/v1/units/wistronFloorInfos`,body);
        return response.data;
    }

    async updateWistronRoom(body:any): Promise<Response<any>> {
        const response = await http.put<Response<any>>(`/api/v1/units/wistronFloorInfos`,body);
        return response.data;
    }

    async deleteWistronRoom(floor:any): Promise<Response<any>> {
        const response = await http.delete<Response<any>>(`/api/v1/units/wistronFloorInfos?id=${floor}`);
        return response.data;
    }

    async getWistronRoom(id:any,floor:any): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/units/roombedId/?id=${id}&floor=${floor}`);
        return response.data;
    }

    async getAllWistronRoom(): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/units/getAllRoom`);
        return response.data;
    }
}

export default new UnitService();
