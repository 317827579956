/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";


// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DeviceTable from "layouts/device/DeviceTable";
import deviceService from "services/device.service";


export const Sales: React.FC = () => {
    const [disconnectData, setDisconnectData] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [list, setList] = useState<any>([]);
    const fetchDisconnectDeviceData = async () => {
        // Fetch device data from API
        const res = await deviceService.getDisconnectDeviceData();
        if (res.success) {
            console.log(res.data);
            setDisconnectData(res.data);
        }
    };
    const fetchDeviceData = async () => {
        // Fetch device data from API
        const res = await deviceService.getDeviceData();
        if (res.success) {
            console.log(res.data);
            setData(res.data);
        }
    };
    useEffect(() => {
        fetchDeviceData();
        fetchDisconnectDeviceData();
    }, []);

    //handle the data, when both data and disconnectData are ready
    useEffect(() => {
        if (data && disconnectData) {
            const newData: any[] = data.map((device: any) => {
                const deviceList: any[] = [];
                if (device.deviceXinfo.length > 0) {
                    device.deviceXinfo.map((deviceX: any) => {
                        const deviceData = disconnectData.find((d: any) => d.sn === deviceX.deviceid);
                        if (deviceData) {
                            console.log(123, deviceData);
                            const timestamp = deviceData.dt * 1000;
                            const currentTimeInMillis = Date.now();
                            const isDisconnected = currentTimeInMillis - timestamp > 60000;
                            if (deviceData) {
                                deviceList.push({
                                    ...deviceX,
                                    type: "anderX",
                                    updateData: deviceData.dt,
                                    disconnected: isDisconnected,
                                });
                            }
                        } else {
                            deviceList.push({
                                ...deviceX,
                                type: "anderX",
                                updateData: null,
                                disconnected: true,
                            });
                        }
                    });
                }
                const deviceData = disconnectData.find((d: any) => device.deviceid === d.sn);
                if (deviceData) {
                    const timestamp = deviceData.dt * 1000;
                    const currentTimeInMillis = Date.now();
                    const isDisconnected = currentTimeInMillis - timestamp > 60000;
                    if (deviceData) {
                        deviceList.push({
                            deviceid: device.deviceid,
                            type: "ander",
                            updateDate: deviceData.dt,
                            disconnected: isDisconnected,
                        });
                    }
                } else {
                    deviceList.push({
                        deviceid: device.deviceid,
                        type: "ander",
                        updateDate: null,
                        disconnected: true,
                    });
                }
                console.log(deviceList);
                return {
                    ...device,
                    disconnectData: deviceList,
                };
            });
            setList(newData);
        }
    }, [data, disconnectData]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <DeviceTable list={list} />
            <Footer />
        </DashboardLayout>
    );
};

export default Sales;
