/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {forwardRef, FC} from "react";

// @mui material components
import {BoxProps} from "@mui/material";

// Custom styles for MDBox
import MDBoxRoot from "components/MDBox/MDBoxRoot";

// declaring props types for MDBox
interface Props extends BoxProps {
    variant?: "contained" | "gradient";
    bgColor?: string;
    color?: string;
    opacity?: number;
    border?: string | number;
    borderRadius?: string;
    shadow?: string;
    coloredShadow?: string;
    [key: string]: any;
}

const MDBox: FC<Props> = forwardRef(
    ({variant, bgColor, color, opacity, border, borderRadius, shadow, coloredShadow, ...rest}, ref) => (
        <MDBoxRoot
            {...rest}
            ref={ref}
            ownerState={{variant, bgColor, color, opacity, border, borderRadius, shadow, coloredShadow}}
        />
    ),
);

// Declaring default props for MDBox
MDBox.defaultProps = {
    variant: "contained",
    bgColor: "transparent",
    color: "dark",
    opacity: 1,
    border: "none",
    borderRadius: "none",
    shadow: "none",
    coloredShadow: "none",
};

export default MDBox;
