/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Theme } from "@mui/material/styles";

const navbar = (theme: Theme | any, ownerState: any) => {
    const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
    const { transparentNavbar } = ownerState;

    const { dark, transparent, white } = palette;
    const { navbarBoxShadow } = boxShadows;
    const { rgba, pxToRem } = functions;
    const { borderRadius } = borders;

    return {
        boxShadow: transparentNavbar ? "none" : navbarBoxShadow,
        backdropFilter: transparentNavbar ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
        backgroundColor: transparentNavbar ? `${transparent.main} !important` : rgba(white.main, 0.9),

        color: dark.main,
        top: pxToRem(12),
        minHeight: pxToRem(75),
        display: "grid",
        alignItems: "center",
        borderRadius: borderRadius.xl,
        paddingTop: pxToRem(8),
        paddingBottom: pxToRem(8),
        paddingRight: 0,
        paddingLeft: 0,
        zIndex: 20,

        "& > *": {
            transition: transitions.create("all", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
            }),
        },

        "& .MuiToolbar-root": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            [breakpoints.up("sm")]: {
                minHeight: "auto",
                padding: `${pxToRem(4)} ${pxToRem(16)}`,
            },
        },
    };
};

const smnavbar = (theme: Theme | any, ownerState: any) => {
    const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
    const { transparentNavbar } = ownerState;

    const { dark, transparent, white } = palette;
    const { navbarBoxShadow } = boxShadows;
    const { rgba, pxToRem } = functions;
    const { borderRadius } = borders;

    return {
        boxShadow: transparentNavbar ? "none" : navbarBoxShadow,
        backdropFilter: transparentNavbar ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
        backgroundColor: transparentNavbar ? `${transparent.main} !important` : rgba(white.main, 0.9),

        color: dark.main,
        top: pxToRem(0),
        minHeight: pxToRem(75),
        display: "grid",
        alignItems: "center",
        borderRadius: borderRadius.xl,
        paddingTop: pxToRem(6),
        paddingBottom: pxToRem(6),
        paddingRight: 0,
        paddingLeft: 0,
        zIndex: 20,

        "& > *": {
            transition: transitions.create("all", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
            }),
        },

        "& .MuiToolbar-root": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            [breakpoints.up("sm")]: {
                minHeight: "auto",
                padding: `${pxToRem(4)} ${pxToRem(16)}`,
            },
        },
    };
};

const navbarContainer = ({ breakpoints }: Theme): any => ({
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    pt: 0.5,
    pb: 0.5,

    [breakpoints.up("md")]: {
        flexDirection: "row",
        alignItems: "center",
        paddingTop: "0",
        paddingBottom: "0",
        px: 2,
    },
});

const navbarRow = ({ breakpoints }: Theme, { isMini }: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    [breakpoints.up("md")]: {
        justifyContent: isMini ? "space-between" : "stretch",
        width: isMini ? "100%" : "max-content",
    },

    [breakpoints.up("xl")]: {
        justifyContent: "stretch !important",
        width: "max-content !important",
    },
});

const navbarIconButton = ({ typography: { size }, breakpoints }: Theme) => ({
    px: 1,

    "& .material-icons, .material-icons-round": {
        fontSize: `50px !important`,
    },

    "& .MuiTypography-root": {
        display: "none",

        [breakpoints.up("sm")]: {
            display: "inline-block",
            lineHeight: 1.2,
            ml: 0.5,
        },
    },
});

const navbarDesktopMenu = ({ breakpoints }: Theme) => ({
    display: "none !important",
    cursor: "pointer",

    [breakpoints.up("xl")]: {
        display: "inline-block !important",
    },
});

const navbarMobileMenu = ({ breakpoints }: Theme) => ({
    display: "inline-block",
    lineHeight: 0,

    [breakpoints.up("xl")]: {
        display: "none",
    },
});

export { smnavbar,navbar, navbarContainer, navbarRow, navbarIconButton, navbarDesktopMenu, navbarMobileMenu };
