/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {ReactNode} from "react";

// @mui material components
import List from "@mui/material/List";

export const SidenavList: React.FC<{children: ReactNode}> = ({children}) => {
    return (
        <List
            sx={{
                px: 2,
            }}>
            {children}
        </List>
    );
};

export default SidenavList;
