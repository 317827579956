/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@mui/material";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// i18n
import { useTranslation } from "react-i18next";

// Declaring props types for the Breadcrumbs
interface Props {
    title: string;
    route: string | string[];
    light?: boolean;
    [key: string]: any;
}

export const Breadcrumbs: React.FC<Props> = ({ title, route, light }) => {
    const navigate = useNavigate();
    const routes: string[] | any = route.slice(0, -1);
    const { t: translate, i18n } = useTranslation(["common"]);
    return (
        <MDBox mr={{ xs: 0, xl: 8 }}>
            <MDBox display="flex" alignItems="center" mb={2}>
                {routes.length > 0 && (
                    <Icon onClick={() => navigate(-1)} sx={{ cursor: "pointer" }}>
                        arrow_back
                    </Icon>
                )}

                <MDTypography variant="h1" ml={routes.length > 0 ? 1 : 0} color={light ? "white" : "dark"}>
                    {i18n.exists(`sidemenu.${title}`) ? translate(`sidemenu.${title}`) : title.replace("-", " ")}
                </MDTypography>
            </MDBox>
            {routes.length > 0 && (
                <MuiBreadcrumbs
                    sx={{
                        "& .MuiBreadcrumbs-separator": {
                            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
                        },
                    }}>
                    {routes.map((el: string) => {
                        const breadcrumb = (
                            <MDTypography
                                variant="h3"
                                color={light ? "white" : "dark"}
                                opacity={light ? 0.8 : 0.5}
                                key={el}
                                sx={{ lineHeight: 0 }}>
                                {translate(`sidemenu.${el}`)}
                            </MDTypography>
                        );
                        if (routes[0] === el) {
                            return breadcrumb;
                        }
                        return (
                            <Link to={`/${routes[0]}/${el}`} key={el}>
                                {breadcrumb}
                            </Link>
                        );
                    })}
                    <MDTypography variant="h3" color={light ? "white" : "dark"}>
                        {/* If title is in i18n, translate */}
                        {i18n.exists(`sidemenu.${title}`) ? translate(`sidemenu.${title}`) : title.replace("-", " ")}
                    </MDTypography>
                </MuiBreadcrumbs>
            )}
        </MDBox>
    );
};

// Declaring default props for Breadcrumbs
Breadcrumbs.defaultProps = {
    light: false,
};

export default Breadcrumbs;
