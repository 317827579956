import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RoomTable } from "types/enums";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import { Theme } from "@mui/material/styles";
import { isNil, isNotNil } from "helpers/utils";
import MDAvatar from "components/MDAvatar";
import HotelIcon from "@mui/icons-material/Hotel";
import KingBedIcon from "@mui/icons-material/KingBed";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import AirlineSeatFlatAngledIcon from "@mui/icons-material/AirlineSeatFlatAngled";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";

import HomeIcon from "@mui/icons-material/Home";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import io from "socket.io-client";
import { Andar, DeviceXinfo, Room, RoomInfo } from "types/websocket-schema";
import { ReactNode, memo, useEffect, useState } from "react";
import { use } from "i18next";
import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import defaultAvatar from "assets/images/defaultAvatar.svg";
import bathroom from "assets/images/icons/realtimeInfo/ic_bathroom.svg";
import bedroom from "assets/images/icons/realtimeInfo/ic_bedroom.svg";
import kitchen from "assets/images/icons/realtimeInfo/ic_kitchen.svg";
import livingroom from "assets/images/icons/realtimeInfo/ic_livingroom.svg";
import inbed from "assets/images/icons/realtimeInfo/ic_inbed.svg";
import leave from "assets/images/icons/realtimeInfo/ic_leave.svg";
import nosleep from "assets/images/icons/realtimeInfo/ic_nosleep.svg";
import sidebed from "assets/images/icons/realtimeInfo/ic_sidebed.svg";
import sleep from "assets/images/icons/realtimeInfo/ic_sleep.svg";
import { min } from "date-fns";
const category = [
    RoomTable.ROOM,
    RoomTable.NAME,
    RoomTable.BEDSTATUS,
    RoomTable.HRRR,
    RoomTable.ROOMSTATUS,
    RoomTable.NOTINROOM,
    RoomTable.DEVICESTATUS,
];

interface Props {
    data: { [key: string]: Room };
    filter?: string;
}
interface RealTimeInfoTableProps {
    filter?: string;
    data?: { [key: string]: Room };
}

export default function RealTimeInfoDetailsTable(props: RealTimeInfoTableProps) {
    const { filter, data } = props;
    const { t: translate } = useTranslation(["common"]);
    if (!data) {
        return (
            <MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                    }}>
                    <MDTypography variant="h5">{translate("info.realTimeInfo.noData")}</MDTypography>
                </MDBox>
            </MDBox>
        );
    }

    const getBedStatus = (status: number) => {
        if (!isNotNil(status))
            return (
                <MDBox
                    sx={{ padding: "0 4px", width: "60px", height: "60px" }}
                    component="img"
                    src={nosleep}
                    alt="nosleep"
                />
            );
        switch (status) {
            case 0:
                return (
                    <MDBox
                        sx={{ padding: "0 4px", width: "60px", height: "60px" }}
                        component="img"
                        src={nosleep}
                        alt="nosleep"
                    />
                );
            case 1:
                return (
                    <MDBox
                        sx={{ padding: "0 4px", width: "60px", height: "60px" }}
                        component="img"
                        src={sidebed}
                        alt="sidebed"
                    />
                );
            case 2:
                return (
                    <MDBox
                        sx={{ padding: "0 4px", width: "60px", height: "60px" }}
                        component="img"
                        src={inbed}
                        alt="inbed"
                    />
                );
            case 4:
                return (
                    <MDBox
                        sx={{ padding: "0 4px", width: "60px", height: "60px" }}
                        component="img"
                        src={sleep}
                        alt="sleep"
                    />
                );
            default:
                return (
                    <MDBox
                        sx={{ padding: "0 4px", width: "60px", height: "60px" }}
                        component="img"
                        src={nosleep}
                        alt="nosleep"
                    />
                );
        }
    };

    const getRoomStatus = (
        kitchen: RoomInfo,
        bedRoom: RoomInfo,
        livingRoom: RoomInfo,
        bathRoom: RoomInfo,
        ander: Andar,
    ) => {
        console.log(ander);
        const roomStatus = (room: RoomInfo | Andar, roomName: string) => {
            if (room?.people === 1) {
                if ("fallDown" in room && room.fallDown) {
                    return (
                        <img
                            style={{ padding: "0 4px", width: "60px", height: "60px" }}
                            src={require(`assets/images/icons/realtimeInfo/ic_${roomName}_fall.svg`)}
                        />
                    );
                }
                if ("toLong" in room && room.fallDown) {
                    return (
                        <img
                            style={{ padding: "0 4px", width: "60px", height: "60px" }}
                            src={require(`assets/images/icons/realtimeInfo/ic_${roomName}_long.svg`)}
                        />
                    );
                }
                if (room.people) {
                    return (
                        <img
                            style={{ padding: "0 4px", width: "60px", height: "60px" }}
                            src={require(`assets/images/icons/realtimeInfo/ic_${roomName}_normal.svg`)}
                        />
                    );
                }
            }
        };

        return (
            roomStatus(kitchen, "kitchen") ||
            roomStatus(bedRoom, "bedroom") ||
            roomStatus(livingRoom, "livingroom") ||
            roomStatus(bathRoom, "bathroom") ||
            roomStatus(ander, "bedroom") || (
                <MDTypography variant="subtitle2" textAlign={"center"}>
                    --
                </MDTypography>
            )
        );
    };

    const isInhouse = (bed: number, livingRoom: number, kitchen: number, bathRoom: number, ander: number) => {
        const isAnyRoomPresent = Boolean(bed || livingRoom || kitchen || bathRoom || ander);
        const areAllRoomsEmpty = !bed && !livingRoom && !kitchen && !bathRoom;
        console.log(123, isAnyRoomPresent);
        if (isAnyRoomPresent) {
            if (areAllRoomsEmpty) {
                return (
                    <MDTypography textAlign="center" fontSize="16px">
                        N/A
                    </MDTypography>
                );
            }
            return (
                <MDTypography textAlign="center" fontSize="16px">
                    --
                </MDTypography>
            );
        } else {
            if (areAllRoomsEmpty) {
                return (
                    <MDTypography textAlign="center" fontSize="16px">
                        N/A
                    </MDTypography>
                );
            }
            return <MDBox sx={{ padding: "0 4px" }} component="img" src={leave} alt="leave" />;
        }
    };
    const isFallAndLong = (bed: boolean, livingRoom: boolean, kitchen: boolean, bathRoom: boolean) => {
        if (bed || livingRoom || kitchen || bathRoom) {
            return true;
        }
        return false;
    };
    const getDeviceStatus = (
        deviceXinfo: DeviceXinfo[],
        ander: Andar,
        kitchen: RoomInfo,
        bedRoom: RoomInfo,
        livingRoom: RoomInfo,
        bathRoom: RoomInfo,
        hasLiveData: boolean,
    ) => {
        const status = {
            kitchen: false,
            bedRoom: false,
            livingRoom: false,
            bathRoom: false,
            bedRoomVs: false,
        };
        const deviceStatus = [];
        if ((deviceXinfo?.length === 0 && !ander) || !hasLiveData) {
            return (
                <MDTypography
                    variant="subtitle2"
                    sx={{
                        textAlign: "center",
                        backgroundColor: "#FF6B6B",
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "60px",
                        padding: "4px 6px",
                        color: "#FFFFFF",
                        borderRadius: "5px",
                    }}>
                    {translate(`info.realTimeInfo.offline`)}
                </MDTypography>
            );
        }
        if (!ander) {
            status.bedRoomVs = true;
        }
        deviceXinfo?.length > 0 &&
            deviceXinfo.forEach((device) => {
                if (device.space === "kitchen") {
                    status.kitchen = true;
                }
                if (device.space === "bedRoom") {
                    status.bedRoom = true;
                }
                if (device.space === "livingRoom") {
                    status.livingRoom = true;
                }
                if (device.space === "bathRoom") {
                    status.bathRoom = true;
                }
            });
        if (status.kitchen && !kitchen) deviceStatus.push("kitchen");
        if (status.bedRoom && !bedRoom) deviceStatus.push("bedRoom");
        if (status.livingRoom && !livingRoom) deviceStatus.push("livingRoom");
        if (status.bathRoom && !bathRoom) deviceStatus.push("bathRoom");
        if (status.bedRoomVs && !ander) deviceStatus.push("bedRoomVs");
        const style = { width: "100%", maxWidth: 120, margin: "auto" };
        return (
            <MDBox sx={translate("lang") == "en" && style}>
                {deviceStatus.map((device) => {
                    return (
                        <MDBox
                            textAlign={"center"}
                            display="flex"
                            // flexDirection="column"
                            justifyContent="left"
                            alignItems="center">
                            <MDBox
                                sx={{
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: "#FF6B6B",
                                    display: "inline-block",
                                    marginRight: "5px",
                                }}
                            />
                            <MDTypography textAlign={"start"} fontSize="16px">
                                {translate(`info.realTimeInfo.${device}`)}
                            </MDTypography>
                        </MDBox>
                    );
                })}
                 {deviceStatus.length === 0 && (
                    <MDTypography
                        variant="subtitle2"
                        sx={{
                            textAlign: "center",
                            backgroundColor: "#4CAF50",
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "60px",
                            padding: "4px 6px",
                            color: "#FFFFFF",
                            borderRadius: "5px",
                        }}>
                        OK
                    </MDTypography>
                )}
            </MDBox>
        );
    };
    const CustomTableRow = memo(function CustomTableRow({ data, filter }: Props) {
        const navigate = useNavigate();
        const handleClick = (residentId?: string) => {
            window.history.pushState({ prevUrl: "/overview/overview-unit" }, null);
            navigate("/overview/profile", { state: { residentId: residentId } });
        };
        return (
            <>
                {Object.keys(data)
                    .filter((key) => !filter || data[key].roomName.includes(filter) || data[key].name.includes(filter))
                    .map((key) => {
                        let isWarning = 0;
                        if (
                            isFallAndLong(
                                data[key]?.bedRoom?.fallDown,
                                data[key]?.kitchen?.fallDown,
                                data[key]?.livingRoom?.fallDown,
                                data[key]?.bathRoom?.fallDown,
                            )
                        ) {
                            isWarning = 1;
                        }
                        if (data[key].andar?.alarm) {
                            isWarning = 2;
                        }
                        return (
                            <TableRow
                                key={`TableRow_${key}`}
                                sx={{
                                    cursor: "pointer",
                                    backgroundColor: data[key].hasLiveData ? "white" : "rgba(0, 0, 0, 0.1)",
                                    "&:hover": {
                                        backgroundColor: "#E9DEEE !important",
                                    },
                                }}
                                onClick={() => handleClick(key)}>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                        width: "10%",
                                    })}>
                                    <MDTypography
                                        variant="subtitle2"
                                        textAlign={"center"}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            float: "left",
                                        }}>
                                        <MDBox
                                            sx={{
                                                height: "10px",
                                                width: "10px",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                    isWarning === 0
                                                        ? " transparent"
                                                        : isWarning === 1
                                                        ? " red"
                                                        : " yellow",
                                                display: "inline-block",
                                            }}
                                        />
                                        <MDAvatar
                                            sx={{
                                                margin: "0px 16px",
                                            }}
                                            src={data[key].avatar ? data[key].avatar : defaultAvatar}
                                        />
                                        <MDBox>{data[key].roomName}</MDBox>
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDTypography variant="subtitle2" textAlign={"center"}>
                                        {data[key].name}
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDTypography variant="subtitle2" textAlign={"center"} fontSize="50px">
                                        {getBedStatus(data[key].andar?.status)}
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDTypography variant="subtitle2" textAlign={"center"}>
                                        {data[key].andar?.bpm ? data[key].andar?.bpm : "--"}/{" "}
                                        {data[key].andar?.rpm ? data[key].andar?.rpm : "--"}
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDTypography
                                        variant="subtitle2"
                                        // textAlign={"center"}
                                        fontSize="50px"
                                        padding="0px 4px"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}>
                                        {getRoomStatus(
                                            data[key].kitchen,
                                            data[key].bedRoom,
                                            data[key].livingRoom,
                                            data[key].bathRoom,
                                            data[key].andar,
                                        )}
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDTypography variant="subtitle2" textAlign={"center"} fontSize="50px">
                                        {isInhouse(
                                            data[key].bedRoom?.people,
                                            data[key].livingRoom?.people,
                                            data[key].kitchen?.people,
                                            data[key].bathRoom?.people,
                                            data[key].andar?.people,
                                        )}
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDBox
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            position: "relative",
                                            width: "100%",
                                            alignItems: "center",
                                        }}>
                                        {getDeviceStatus(
                                            data[key].deviceXinfo,
                                            data[key].andar,
                                            data[key].kitchen,
                                            data[key].bedRoom,
                                            data[key].livingRoom,
                                            data[key].bathRoom,
                                            data[key].hasLiveData,
                                        )}
                                    </MDBox>
                                </MDBox>
                            </TableRow>
                        );
                    })}
            </>
        );
    });
    return (
        <TableContainer sx={{ backgroundColor: "white !important", borderRadius: 3 }}>
            <Table sx={{ minWidth: 900 }} aria-label="simple table">
                <MDBox component="thead">
                    {category.map((item, i) => (
                        <TableCell key={i} align={"center"}>
                            <MDTypography
                                variant="subtitle2"
                                key={i}
                                sx={{
                                    display: "inline-block",
                                    fontWeight: "medium",
                                    mx: item !== RoomTable.ROOM ? 2 : 0,
                                    paddingTop: "20px",
                                }}>
                                {item ? translate(`info.realTimeInfo.${item}`) : ""}
                            </MDTypography>
                        </TableCell>
                    ))}
                </MDBox>

                <TableBody>
                    <CustomTableRow data={data} filter={filter} />
                </TableBody>
            </Table>
        </TableContainer>
    );
}
